import * as React from 'react';

export interface MySelectInputProps {
  bodyPortal?: boolean;
  className?: string | undefined;
  containerClassName?: string | undefined;
  isLoading?: boolean;
  isClearable?: boolean;
  label?: string;
  name: string;
  onChange?(name: number | string | boolean): any;
  onInputChange?: (value: string) => void;
  options: MySelectInputOptions[];
  placeholder?: string | null;
  style?: React.CSSProperties;
  value?: any | null;
  disabled?: boolean;
}

export interface MySelectInputOptions {
  value: number | string | boolean;
  label: string;
  icon?: React.ReactNode;
}

export const yesnoOptions: MySelectInputOptions[] = [
  {
    label: 'TAK',
    value: true,
  },
  {
    label: 'NIE',
    value: false,
  },
];

export const noyesOptions: MySelectInputOptions[] = [...yesnoOptions].reverse();
