import { motion } from 'motion/react';
import { twMerge } from '../../../index';

interface FancyNextStepButtonProps {
  onClick: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  variant?: 'primary' | 'secondary';
}

export const FancyButton = ({ onClick, disabled, children = 'Next Step', variant }: FancyNextStepButtonProps) => {
  return (
    <motion.button
      className={twMerge(
        `tw-relative tw-overflow-hidden
        tw-rounded-full tw-bg-gradient-to-r tw-from-nau-sea-green tw-to-nau-green-light
        tw-px-6 tw-py-3 tw-font-semibold
        tw-text-white focus:tw-outline-none`,
        variant === 'secondary' && 'tw-from-gray-400 tw-to-gray-500',
      )}
      onClick={onClick}
      transition={{ type: 'spring', stiffness: 400, damping: 20 }}
      type={'button'}
      whileTap={{ scale: 0.95 }}
      disabled={disabled}
      // Animate on hover and tap
      whileHover={{
        scale: 1.05,
        boxShadow: '0px 0px 20px rgba(255,255,255,0.8)',
      }}>
      {/* The button text */}
      <span className='tw-relative tw-z-10'>{children}</span>

      {/* A subtle animated overlay for a pulsing glow effect */}
      <motion.div
        animate={{ opacity: [0, 0.1, 0] }}
        className='tw-absolute tw-inset-0 tw-rounded-full tw-bg-white'
        initial={{ opacity: 0 }}
        transition={{ duration: 2, loop: Infinity, ease: 'easeInOut' }}
      />
    </motion.button>
  );
};
