export enum IFRegisterOrderPaymentType {
  PaidByTransferToOrderedBy = 1,
  PaidDirectlyByNAU = 2,
}

export enum IFOrderStatus {
  Pending = 1,
  SendToEnova = 2,
  Cancelled = 3,
  Rejected = 4,
  SubstantiveAcceptance = 5,
  Realized = 6,
}
