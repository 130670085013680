import { motion } from 'framer-motion';
import { MySelectInputOptions } from '../MySelectInput/types';
import { twMerge } from '../../../index';
import { CircleCheckBig } from 'lucide-react';

interface IMyMultiSelectBoxInputProps {
  label: string;
  name: string;
  value: any[];
  options: MySelectInputOptions[];
  onChange: (value: any[]) => void;
  error?: string;
  wrapperClassName?: string;
  itemClassName?: string;
  withCheckIcon?: boolean;
  renderOption?: (option: MySelectInputOptions) => React.ReactNode;
}

export const MyMultiSelectBoxInput = ({
  label,
  name,
  value = [],
  options,
  onChange,
  error,
  wrapperClassName,
  itemClassName,
  withCheckIcon,
  renderOption,
}: IMyMultiSelectBoxInputProps) => {
  const handleSelect = (selectedValue: any) => {
    const newValue = value.includes(selectedValue)
      ? value.filter((v) => v !== selectedValue)
      : [...value, selectedValue];
    onChange(newValue);
  };

  return (
    <div>
      <label className='tw-mb-4 tw-block tw-font-bold tw-text-gray-600'>{label}</label>
      <div className={twMerge('tw-flex tw-flex-wrap tw-gap-2', wrapperClassName)}>
        {options.map((option) => {
          const isSelected = value.includes(option.value);
          return (
            <motion.div
              className={twMerge(
                `tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-border tw-p-2 tw-text-center ${
                  isSelected ? 'tw-bg-nau-sea-green tw-text-white' : 'tw-border-gray-300 tw-bg-gray-600 tw-text-white'
                }`,
                'tw-relative',
                itemClassName,
              )}
              data-selected={isSelected}
              key={option.value.toString()}
              onClick={() => handleSelect(option.value)}
              whileHover={{ scale: 1.05 }}>
              {renderOption ? renderOption(option) : option.label}
              {withCheckIcon && isSelected && <CircleCheckBig className={'tw-absolute tw-left-1 tw-top-1'} />}
            </motion.div>
          );
        })}
      </div>
      {error && <p className='tw-mt-1 tw-text-sm tw-text-red-500'>{error}</p>}
    </div>
  );
};
