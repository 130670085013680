import { observer } from 'mobx-react-lite';
import React from 'react';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { CinemaVoucherOrderPromo } from '../../../enums/cinemaVoucherOrderPromo.enum';
import UserPersonalDataLayout from '../../UserPersonalData/UserPersonalDataLayout';
import CinemaVoucherCart from './CinemaVoucherCart';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import CinemaVoucherPersonalPromotionCart from './CinemaVoucherPersonalPromotionCart';
import CinemaVoucherWinterBreakPromotionCart from './CinemaVoucherWinterBreakPromotionCart';

export default observer(() => {
  const { data: isUserAssignedToInstitutionAsDirector, isLoading: isLoadingIsUserAssignedToInstitutionAsDirector } =
    useSwrAgent().Users.IsUserAssignedToInstitutionAsDirector();
  const { data: hasUserCollectedFreeCinemaVouchers, isLoading: isLoadingHasUserCollectedFreeCinemaVouchers } =
    useSwrAgent().CinemaVouchers.CheckUserHasCinemaVoucherOrderWithPromo(
      CinemaVoucherOrderPromo.InstitutionDirectorPromo,
    );
  const isBeforeMarch3 = (): boolean => {
    const today = new Date();
    const date = new Date('2025-03-03');
    return today < date;
  };
  const {
    data: hasUserCollectedFreeTeachersDayCinemaVouchers,
    isLoading: isLoadingHasUserCollectedFreeTeachersDayCinemaVouchers,
  } = useSwrAgent().CinemaVouchers.CheckUserHasCinemaVoucherOrderWithPromo(
    CinemaVoucherOrderPromo.InstitutionDirectorTeachersDayPromo,
  );
  const { data: hasUserActivePersonalPromotion, isLoading: isLoadingHasUserActivePersonalPromotion } =
    useSwrAgent().CinemaVouchers.CheckIfUserHasCinemaVoucherPersonalPromotions();

  const isDirectorPromoLayout = isUserAssignedToInstitutionAsDirector && !hasUserCollectedFreeCinemaVouchers;
  const isDirectorTeachersDayPromoLayout =
    isUserAssignedToInstitutionAsDirector && !hasUserCollectedFreeTeachersDayCinemaVouchers;
  const isPersonalPromoLayout = hasUserActivePersonalPromotion;
  const params = new URLSearchParams(location.search);
  const winterBreak = params.get('promo') === 'ferie';
  const isPromoLayoutWinterBreak = winterBreak && isBeforeMarch3();

  const getPromoType = () => {
    if (isPersonalPromoLayout) return 'PERSONAL';
    if (isPromoLayoutWinterBreak) return 'WINTER_BREAK';
    if (isDirectorPromoLayout || isDirectorTeachersDayPromoLayout) return 'DIRECTOR';
    return 'DEFAULT';
  };

  const promoType = getPromoType();

  return (
    <>
      {isLoadingIsUserAssignedToInstitutionAsDirector ||
      isLoadingHasUserCollectedFreeTeachersDayCinemaVouchers ||
      isLoadingHasUserActivePersonalPromotion ||
      isLoadingHasUserCollectedFreeCinemaVouchers ? (
        <LoadingComponent />
      ) : null}

      {(() => {
        switch (promoType) {
          case 'PERSONAL':
            return <CinemaVoucherPersonalPromotionCart />;
          case 'WINTER_BREAK':
            return (
              <UserPersonalDataLayout wrapperClassName={' tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6 tw-rounded-lg'}>
                <CinemaVoucherWinterBreakPromotionCart />
              </UserPersonalDataLayout>
            );
          case 'DIRECTOR':
            return <CinemaVoucherCart />;
          case 'DEFAULT':
          default:
            return (
              <UserPersonalDataLayout wrapperClassName={' tw-bg-white tw-px-2 tw-py-6 sm:tw-px-6 tw-rounded-lg'}>
                <CinemaVoucherCart />
              </UserPersonalDataLayout>
            );
        }
      })()}
    </>
  );
});
