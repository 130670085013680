import agent from '../../../api/agent';
import { Form, Formik, FormikHelpers } from 'formik';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import MyLinkIcon from '../../Icons/MyLinkIcon';
import { TrashIcon } from '@heroicons/react/24/outline';
import { IFRegisterOrderPaymentType } from '../../../enums/institutionFund.enums';
import { twMerge } from '../../../index';
import MyButton from '../../Buttons/MyButton/MyButton';
import { toast } from 'react-toastify';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { motion } from 'framer-motion';
import MaskHelpers from '../../../helpers/MaskHelpers';
import BuildingSackIcon from '../../Icons/BuildingShieldIcon';
import EllipseIcon from '../../Icons/EllipseIcon';
import { Button } from 'react-bootstrap';
import { handleErrorResponse } from '../../../utils/apiErrorHandle';
import { isValidPolishIban } from '../../../utils/bankAcountValidation';
import RegexHelpers from '../../../helpers/RegexHelpers';

const validationSchema = Yup.object().shape({
  invoiceNumber: Yup.string().nullable().required('Pole jest wymagane'),
  grossAmount: Yup.string().matches(
    RegexHelpers.moneyRegex,
    'Wprowadź poprawną kwotę (np. "1000", "1000.50" lub "1000,50")',
  ),
  refundBankAccountNumber: Yup.string().when('paymentType', {
    is: IFRegisterOrderPaymentType.PaidByTransferToOrderedBy,
    then: Yup.string()
      .required('Pole jest wymagane')
      .transform((value) => value.replace(/\s+/g, ''))
      .test('is-valid-iban', 'Nieprawidłowy numer', (value) => {
        if (!value) return false;
        return isValidPolishIban(value);
      }),
    otherwise: Yup.string(),
  }),
  refundAccountHolderName: Yup.string().when('paymentType', {
    is: IFRegisterOrderPaymentType.PaidByTransferToOrderedBy,
    then: Yup.string().required('Pole jest wymagane'),
    otherwise: Yup.string(),
  }),
});

interface MakeInstitutionFundOrderFormValues {
  institutionId: number;
  grossAmount?: number;
  files: File[];
  invoiceNumber: string;
  paymentType?: IFRegisterOrderPaymentType;
  refundBankAccountNumber: string;
  refundAccountHolderName: string;
  token: string;
}

interface IMakeInstitutionFundOrderFormProps {
  institutionId: number;
  onSave?: () => void;
}

export function MakeInstitutionFundOrderForm(props: IMakeInstitutionFundOrderFormProps) {
  const [isAuthCodeGenerated, setIsAuthCodeGenerated] = useState<boolean>(false);
  const { data: fundDetails, isLoading, mutate } = useSwrAgent().InstitutionAdmin.GetFundDetails(props.institutionId);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div>
      {isLoading && <LoadingComponent />}
      <Formik
        initialValues={{
          grossAmount: undefined,
          invoiceNumber: '',
          files: [],
          institutionId: props.institutionId,
          paymentType: undefined,
          refundBankAccountNumber: '',
          refundAccountHolderName: '',
          token: '',
        }}
        onSubmit={async (
          values,
          { setErrors, setSubmitting, setFieldError }: FormikHelpers<MakeInstitutionFundOrderFormValues>,
        ) => {
          try {
            if (values.files.length === 0) {
              toast.error('Prosimy o wgranie pliku faktury');
              return;
            }
            if ((values.grossAmount ?? 0) > (fundDetails?.configurationDetails.currentBalance ?? 0)) {
              toast.error('Kwota na fakturze nie może przekraczać dostępnych środków');
              return;
            }

            if (!isAuthCodeGenerated) {
              await agent.InstitutionAdmin.sendUserAuthorizationToken(values.institutionId);
              setIsAuthCodeGenerated(true);
              return;
            } else if (!values.token) {
              toast.error('Proszę podać kod sms');
              return;
            }

            const formData = new FormData();
            formData.append('institutionId', values.institutionId.toString() || '');
            formData.append('grossAmount', values.grossAmount?.toString().replace('.', ',') || '');
            formData.append('invoiceNumber', values.invoiceNumber || '');
            formData.append('paymentType', values.paymentType?.toString() || '');
            formData.append('refundBankAccountNumber', values.refundBankAccountNumber);
            formData.append('refundAccountHolderName', values.refundAccountHolderName);
            formData.append('authorizationToken', values.token);
            values.files.forEach((file, index) => {
              formData.append(`files`, file);
            });

            await agent.InstitutionAdmin.addInstitutionFundInvoice(formData);
            toast.success('Formularz został wysłany');
            props.onSave?.();
          } catch (error) {
            handleErrorResponse(error, setFieldError);
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}>
        {({ isSubmitting, setFieldValue, values, errors }) => (
          <Form className='tw-w-full '>
            {values.paymentType ? (
              <div className={'tw-py-4'}>
                <div className={'tw-mb-4 tw-text-xl tw-font-bold tw-text-nau-green-dark'}>
                  {values.paymentType === IFRegisterOrderPaymentType.PaidByTransferToOrderedBy
                    ? 'Faktura opłacona'
                    : 'Faktura nieopłacona'}
                </div>

                <div className={'tw-mb-4 tw-font-normal tw-text-nau-green-dark'}>
                  Dostępne środki:{' '}
                  <span className={'tw-font-bold'}>{fundDetails?.configurationDetails.currentBalance} zł</span>
                </div>
                <div className={'tw-mb-4 tw-font-normal  tw-text-nau-green-dark'}>
                  Prosimy o uzupełnienie pól oraz wgranie pliku z fakturą
                </div>
                <div className='tw-mb-6 tw-flex tw-flex-col tw-gap-4'>
                  <MyTextInput name='grossAmount' placeholder={`Kwota na fakturze`} type='text' />
                  <MyTextInput name='invoiceNumber' placeholder='Numer faktury' />
                  {values.paymentType === IFRegisterOrderPaymentType.PaidByTransferToOrderedBy && (
                    <>
                      <MyTextInput name='refundAccountHolderName' placeholder='Odbiorca zwrotu' />
                      <MyTextInput
                        error={errors.refundBankAccountNumber}
                        mask={MaskHelpers.bankAccountNumber}
                        name='refundBankAccountNumber'
                        placeholder='Numer konta do zwrotu'
                      />
                    </>
                  )}

                  <div>
                    <div
                      className={`tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-xl tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-1.5 `}
                      onClick={handleClick}
                      role='button'>
                      <div className='tw-flex tw-items-center tw-space-x-2'>
                        <MyLinkIcon className='tw-h-3 tw-w-4 tw-text-gray-500' />
                        <div>Załącz plik/i z fakturą</div>
                      </div>
                      <input
                        className='tw-hidden'
                        onChange={(event) => {
                          const selectedFiles = Array.from(event.currentTarget.files || []);
                          setFieldValue('files', [...values.files, ...selectedFiles]);
                        }}
                        ref={fileInputRef}
                        type='file'
                      />
                    </div>
                    {/* List of files */}
                    <div className='tw-mt-4 tw-list-none'>
                      {values.files.map((file, index) => (
                        <div className='tw-mb-2 tw-flex tw-items-center tw-justify-between' key={index}>
                          <button
                            className='tw-flex tw-gap-1 tw-text-gray-800 tw-underline'
                            onClick={() => {
                              const url = URL.createObjectURL(file);
                              const a = document.createElement('a');
                              a.href = url;
                              a.download = file.name;
                              document.body.appendChild(a);
                              a.click();
                              document.body.removeChild(a);
                              URL.revokeObjectURL(url);
                            }}
                            type='button'>
                            {file.name}
                          </button>
                          <button
                            className='tw-font-bold tw-text-red-500'
                            onClick={() => {
                              const newFiles = values.files.filter((_, i) => i !== index);
                              setFieldValue('files', newFiles);
                            }}
                            type='button'>
                            <TrashIcon className={'tw-h-5 tw-w-5 tw-text-red-500'} />
                          </button>
                        </div>
                      ))}
                    </div>
                    {isAuthCodeGenerated && (
                      <motion.div
                        animate={{
                          opacity: 1,
                          x: 0,
                          scale: 1,
                          height: 'auto',
                        }}
                        className={'tw-mt-6'}
                        exit={{
                          opacity: 0,
                          x: 0,
                          scale: 0.9,
                          height: 0,
                        }}
                        initial={{
                          opacity: 0,
                          x: 0,
                          scale: 0.9,
                          height: 0,
                        }}
                        transition={{ duration: 0.1 }}>
                        <MyTextInput
                          label={`Na przypisany autoryzacyjny numer telefonu został wysłany token sms. Prosimy o jego
                          wprowadzenie.`}
                          mask={MaskHelpers.token}
                          name='token'
                          type={'number'}
                        />
                      </motion.div>
                    )}
                  </div>
                </div>
                <MyButton className={'tw-w-full'} isLoading={isSubmitting} type='submit' variant={'primary'}>
                  Wyślij
                </MyButton>
              </div>
            ) : (
              <div className={'tw-mx-auto tw-flex tw-max-w-sm tw-flex-col tw-gap-6 tw-py-6'}>
                <div className={'tw-text-xl tw-font-bold tw-text-nau-green-dark'}>Wykorzystaj fundusz na zakupy</div>
                <div
                  className={twMerge(
                    'tw-flex tw-cursor-pointer tw-flex-col  tw-gap-4 tw-rounded-xl',
                    ' tw-border tw-border-gray-300 tw-p-4 tw-text-xl tw-shadow tw-transition-all hover:tw-scale-105',
                  )}
                  onClick={() => setFieldValue('paymentType', IFRegisterOrderPaymentType.PaidDirectlyByNAU)}>
                  <div className={'tw-flex tw-items-center tw-gap-4'}>
                    <div className={'tw-relative'}>
                      <EllipseIcon className={' tw-h-[76px] tw-w-[76px] tw-text-[#00A2B3] '} />
                      <BuildingSackIcon
                        className={
                          'tw-absolute tw-left-1/2 tw-top-1/2  tw-z-50 tw-h-[58px] tw-translate-x-[-50%] tw-translate-y-[-50%] tw-transform tw-text-white'
                        }
                      />
                    </div>
                    <div>
                      <div className={'tw-text-xl tw-font-bold tw-text-nau-green-dark'}>Faktura nieopłacona</div>
                      <div className={'tw-pt-1 tw-text-[13px] tw-font-normal tw-leading-none tw-text-nau-green-dark'}>
                        Faktura z odroczonym terminem płatności. Środki z Funduszu przelewamy bezpośrednio do
                        sprzedawcy.
                      </div>
                    </div>
                  </div>
                  <Button className={'tw-mt-4 tw-w-full'} variant={'primary'}>
                    Dalej
                  </Button>
                </div>
                <div
                  className={twMerge(
                    'tw-flex tw-cursor-pointer tw-flex-col  tw-gap-4 tw-rounded-xl',
                    ' tw-border tw-border-gray-300 tw-p-4 tw-text-xl tw-shadow tw-transition-all hover:tw-scale-105',
                  )}
                  onClick={() => setFieldValue('paymentType', IFRegisterOrderPaymentType.PaidByTransferToOrderedBy)}>
                  <div className={'tw-flex tw-items-center tw-gap-4'}>
                    <div className={'tw-relative'}>
                      <EllipseIcon className={' tw-h-[76px] tw-w-[76px] tw-text-[#0CB393] '} />
                      <BuildingSackIcon
                        className={
                          'tw-absolute tw-left-1/2 tw-top-1/2  tw-z-50 tw-h-[58px] tw-translate-x-[-50%] tw-translate-y-[-50%] tw-transform tw-text-white'
                        }
                      />
                    </div>
                    <div>
                      <div className={'tw-text-xl tw-font-bold tw-text-nau-green-dark'}>Faktura opłacona</div>
                      <div className={'tw-pt-1 tw-text-[13px] tw-font-normal tw-leading-none tw-text-nau-green-dark'}>
                        Faktura opłacona przy zakupie. Środki z Funduszu zwracamy na konto wskazane przez Państwa.
                      </div>
                    </div>
                  </div>
                  <Button className={'tw-mt-4 tw-w-full'} variant={'primary'}>
                    Dalej
                  </Button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
