import React, { useEffect, useRef, useState } from 'react';
import { MySelectInputOptions, noyesOptions, yesnoOptions } from '../../../components/FormControls/MySelectInput/types';
import { useStore } from '../../../store/store';
import { VehicleInsuranceFormStepE, VehicleTypeE } from './vehicleInsuranceEnum';
import agent from '../../../api/agent';
import { IAddInsuranceFormValues, IGovVehicleHistoryForm, VehicleInsuranceResultData } from './types';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { LoadingCarBackdrop } from './LoadingCarBackdrop';
import { AnimatePresence, motion } from 'motion/react';
import { FancyButton } from './FancyButton';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { MySelectBoxInput } from '../../../components/FormControls/MySelectBoxInput/MySelectBoxInput';
import SubpageHeader from '../../../components/SubpageHeader/SubpageHeader';
import { twMerge } from '../../../index';
import { UserProfileNewModel } from '../../../types/user';
import VehicleInsuranceSummary from './VehicleInsuranceSummary';
import {
  addVehicleInsuranceFormSchema,
  defaultAddInsuranceFormValues,
  insuranceCompaniesOptions,
  vehicleOcCarSteps,
  vehicleOcCommonSteps,
  vehicleOcMotorcycleSteps,
  vehicleOCStepFields,
  vehicleOcTruckSteps,
} from './values';
import { Bot, Car, Database, ThumbsDown, ThumbsUp } from 'lucide-react';
import { AddressPromptingFormRHF } from '../../../components/Address/AddressPromptingFormRHF';
import { extractKmAndKw } from './utils';
import { VehicleInsuranceOffers } from './VehicleInsuranceOffers';
import { MyMultiSelectBoxInput } from '../../../components/FormControls/MySelectBoxInput/MyMultiSelectBoxInput';
import { MyTextInputRHF } from '../../../components/FormControls/ReactHookForm/MyTextInputRHF';

const vehicleTypeDropdownOptions: MySelectInputOptions[] = [
  { label: VehicleTypeE.CAR, value: VehicleTypeE.CAR },
  { label: VehicleTypeE.MOTORCYCLE, value: VehicleTypeE.MOTORCYCLE },
  { label: VehicleTypeE.TRUCK, value: VehicleTypeE.TRUCK },
];

const policyTypeOptions: MySelectInputOptions[] = [
  { label: 'OC', value: 'OC' },
  { label: 'AC', value: 'AC' },
];

interface IVehicleInsuranceFormProps {
  userProfile?: UserProfileNewModel;
}

export function VehicleInsuranceForm(props: IVehicleInsuranceFormProps) {
  const { userStore, modalStore } = useStore();
  const [isLoading, setIsLoading] = useState<{ govVehicle: boolean; basic: boolean; pdfWithOffers: boolean }>({
    govVehicle: false,
    basic: false,
    pdfWithOffers: false,
  });
  const [currentStep, setCurrentStep] = useState<VehicleInsuranceFormStepE>(
    VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES,
  );
  const [calculationResult, setCalculationResult] = useState<VehicleInsuranceResultData | undefined>(undefined);
  const currentStepRef = useRef<VehicleInsuranceFormStepE>(VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES);

  const nau24dropdownOptions = useRef<{
    vehicleType: MySelectInputOptions[];
    brand: MySelectInputOptions[];
    model: MySelectInputOptions[];
    fuel: MySelectInputOptions[];
    capacity: MySelectInputOptions[];
    power: MySelectInputOptions[];
    gearbox: MySelectInputOptions[];
    secondType: MySelectInputOptions[];
    modelAutoExpert: MySelectInputOptions[];
    versionAutoExpert: MySelectInputOptions[];
  }>({
    vehicleType: [
      { label: 'Samochody osobowe i Samochody terenowe', value: 10 },
      { label: 'Motocykle', value: 60 },
    ],
    brand: [],
    model: [],
    fuel: [],
    capacity: [],
    power: [],
    gearbox: [],
    secondType: [],
    modelAutoExpert: [],
    versionAutoExpert: [],
  });

  const methods = useForm<IAddInsuranceFormValues>({
    // @ts-ignore
    resolver: yupResolver<any>(addVehicleInsuranceFormSchema),
    defaultValues: {
      ...defaultAddInsuranceFormValues,
      firstName: props.userProfile?.firstName ?? '',
      lastName: props.userProfile?.lastName ?? '',
      phone: props.userProfile?.phone ?? '',
      ownerSectionPhoneNumber: props.userProfile?.phone ?? '',
      email: props.userProfile?.email ?? '',
      ownerSectionEmail: props.userProfile?.email ?? '',
      pesel: props.userProfile?.pesel ?? '',
      ownerSectionPostCode: props.userProfile?.userMainAddress?.postCode ?? '',
      ownerSectionCity: props.userProfile?.userMainAddress?.place ?? '',
    },
  });

  const watchedValues = methods.watch();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    setValue,
    getValues,
    trigger,
    reset,
  } = methods;

  console.log('errors', errors);

  const getNau24Option = (type: keyof typeof nau24dropdownOptions.current, key: keyof IAddInsuranceFormValues) =>
    nau24dropdownOptions.current[type]?.find(
      (option) => option.label.toLowerCase() === getValues(key)?.toString().toLowerCase(),
    );

  const getNau24VehicleType = () => getNau24Option('vehicleType', 'vehicleType');
  const getNau24Brand = () => getNau24Option('brand', 'vehicleBrand');
  const getNau24Model = () => getNau24Option('model', 'vehicleModel');
  const getNau24Fuel = () => getNau24Option('fuel', 'vehicleFuelType');
  const getNau24Power = () =>
    nau24dropdownOptions.current.power?.find(
      (option) => extractKmAndKw(option.label)?.kw === parseInt(getValues('vehicleEnginePower')),
    );
  const getNau24Gearbox = () => getNau24Option('gearbox', 'vehicleGearboxType');

  const getVehicleBrands = async () => {
    const vehicleType = getNau24VehicleType();
    const response = await agent.AiOffice.getAllBrandsNau24(parseInt(vehicleType?.value.toString() ?? '0'));
    nau24dropdownOptions.current.brand = response;
  };

  const getVehicleModels = async (productionYear: string) => {
    const vehicleType = getNau24VehicleType();
    const brand = getNau24Brand();

    const response = await agent.AiOffice.getAllModelsNau24({
      MarkaId: brand?.value.toString() || '',
      RodzajId: parseInt(vehicleType?.value.toString() ?? '0'),
      RokProdukcji: productionYear,
    });

    const tokens = getValues('vehicleModelGov').trim().split(/\s+/).filter(Boolean);
    const filteredModels = response.filter(
      (model) =>
        getValues('vehicleType') === VehicleTypeE.MOTORCYCLE ||
        tokens.length === 0 ||
        tokens.every((token) => model.label.toLowerCase().includes(token.toLowerCase())),
    );

    nau24dropdownOptions.current.model = filteredModels;
    return filteredModels;
  };

  const getFuelTypes = async () => {
    const formModel = getValues();
    const brand = getNau24Option('brand', 'vehicleBrand');
    const model = getNau24Option('model', 'vehicleModel');

    const response = await agent.AiOffice.getAllFuelTypeNau24({
      PojazdSpozaBazyPojazdow: 0,
      MarkaId: brand?.value.toString() || '',
      RodzajId: parseInt(getNau24VehicleType()?.value.toString() ?? '0'),
      RokProdukcji: formModel.vehicleProductionYear,
      ModelId: model?.value.toString() || '',
      Pojemnosc: parseInt(formModel.vehicleEngineCapacity),
    });

    nau24dropdownOptions.current.fuel = response;
    return response;
  };

  const getVehiclePowers = async () => {
    const response = await agent.AiOffice.getAllPower({
      MarkaId: getNau24Brand()?.value.toString() || '',
      RodzajId: parseInt(getNau24VehicleType()?.value.toString() ?? '0'),
      RokProdukcji: getValues('vehicleProductionYear'),
      ModelId: getNau24Model()?.value.toString() || '',
      Pojemnosc: parseInt(getValues('vehicleEngineCapacity')),
      RodzajPaliwaId: getNau24Fuel()?.value.toString() || '',
    });

    nau24dropdownOptions.current.power = response;
  };

  const getGearboxes = async () => {
    const response = await agent.AiOffice.getAllGearboxNau24({
      MarkaId: getNau24Brand()?.value.toString() || '',
      RodzajId: parseInt(getNau24VehicleType()?.value.toString() ?? '0'),
      RokProdukcji: getValues('vehicleProductionYear'),
      ModelId: getNau24Model()?.value.toString() || '',
      Pojemnosc: parseInt(getValues('vehicleEngineCapacity')),
      RodzajPaliwaId: getNau24Fuel()?.value.toString() || '',
      MocWKM: extractKmAndKw(getNau24Power()?.label)?.km || 0,
    });

    nau24dropdownOptions.current.gearbox = response;
    return response;
  };

  const getModelSecondTypes = async () => {
    const formModel = getValues();
    const response = await agent.AiOffice.getAllSecondTypesNau24({
      MarkaId: getNau24Brand()?.value.toString() || '',
      RodzajId: parseInt(getNau24VehicleType()?.value.toString() ?? '0'),
      RokProdukcji: parseInt(formModel.vehicleProductionYear),
      ModelId: getNau24Model()?.value.toString() || '',
      Pojemnosc: parseInt(formModel.vehicleEngineCapacity),
      RodzajPaliwaId: getNau24Fuel()?.value.toString() || '',
      MocWKM: extractKmAndKw(getNau24Power()?.label)?.km || 0,
      RodzajSkrzyniBiegowId: getNau24Gearbox()?.value.toString() || '',
    });

    nau24dropdownOptions.current.secondType = response;
    return response;
  };

  const getModelsInfoExpert = async () => {
    const formModel = getValues();
    const secondType = getNau24Option('secondType', 'vehicleEquipmentType');
    const drzwiMatch = secondType?.label.match(/l\. drzwi:\s*(\d+)/i);
    const miejscMatch = secondType?.label.match(/l\. miejsc:\s*(\d+)/i);

    const response = await agent.AiOffice.getAllModelsInfoExpertNau24({
      MarkaTekst: formModel.vehicleBrand,
      RodzajId: parseInt(getNau24VehicleType()?.value.toString() ?? '0'),
      RokProdukcji: parseInt(formModel.vehicleProductionYear),
      ModelTekst: formModel.vehicleModel,
      Pojemnosc: parseInt(formModel.vehicleEngineCapacity),
      RodzajPaliwaId: getNau24Fuel()?.value.toString() || '',
      MocWKW: parseInt(formModel.vehicleEnginePower.replace(/\D/g, '')),
      RodzajSkrzyniBiegowId: getNau24Gearbox()?.value.toString() || '',
      IloscDrzwi: drzwiMatch?.[1] || '0',
      IloscMiejsc: parseInt(miejscMatch?.[1] ?? '0'),
      TypId: parseInt(secondType?.value.toString() || '0'),
    });

    const filteredOptions = response.filter((model) => model.value !== -1);
    nau24dropdownOptions.current.modelAutoExpert = response;
    return filteredOptions;
  };

  const getVersionsInfoExpert = async () => {
    const formModel = getValues();
    const infoExpertModel = getNau24Option('modelAutoExpert', 'vehicleModelAutoExpert');
    const secondType = getNau24Option('secondType', 'vehicleEquipmentType');
    const drzwiMatch = secondType?.label.match(/l\. drzwi:\s*(\d+)/i);
    const miejscMatch = secondType?.label.match(/l\. miejsc:\s*(\d+)/i);

    const response = await agent.AiOffice.getAllVersionsInfoExpertNau24({
      MarkaTekst: formModel.vehicleBrand,
      RodzajId: parseInt(getNau24VehicleType()?.value.toString() ?? '0'),
      ModelId: infoExpertModel?.value.toString() || '',
      RokProdukcji: parseInt(formModel.vehicleProductionYear),
      Pojemnosc: parseInt(formModel.vehicleEngineCapacity),
      EurotaxRodzajPaliwa: getNau24Fuel()?.value.toString() || '',
      MocWKW: parseInt(formModel.vehicleEnginePower.replace(/\D/g, '')),
      RodzajSkrzyniBiegowId: getNau24Gearbox()?.value.toString() || '',
      IloscDrzwi: drzwiMatch?.[1] || '0',
      IloscMiejsc: parseInt(miejscMatch?.[1] ?? '0'),
      TypId: parseInt(secondType?.value.toString() || '0'),
    });

    const filteredOptions = response.filter((model) => model.value !== -1);
    nau24dropdownOptions.current.versionAutoExpert = response;
    return filteredOptions;
  };

  const mapVehicleNameFromGovHistory = (name: string) => {
    if (name === 'samochód osobowy') {
      return 'Samochody osobowe i Samochody terenowe';
    }
    if (name === 'motocykl') {
      return 'Motocykle';
    }
    return '';
  };

  const mapFuelTypeFromGovHistory = (name: string) => {
    if (name === 'olej napędowy') {
      return 'Diesel';
    }
    if (name === 'benzyna') {
      return 'Benzyna';
    }
    return '';
  };

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const onSubmit = async (data: IAddInsuranceFormValues) => {
    try {
      setIsLoading((prev) => ({ ...prev, pdfWithOffers: true }));
      const requestModel = {
        ...data,
        ocSectionLastInsuredByCurrentOwner: data.ocSectionLastInsuredByCurrentOwner ?? false,
        ocSectionPrevOwnerHadOc: data.ocSectionPrevOwnerHadOc ?? false,
        acSectionLastInsuredByCurrentOwner: data.acSectionLastInsuredByCurrentOwner ?? false,
        acSectionPrevOwnerHadAc: data.acSectionPrevOwnerHadAc ?? false,
        otherDataSectionVehicleRegisteredAsTruck: data.otherDataSectionVehicleRegisteredAsTruck ?? false,
      };
      const response = await agent.AiOffice.downloadNau24OfferPdf(requestModel);
      setCalculationResult(response);
      // FileHelper.downloadFileFromBlob(response.data, 'OfertaOcNau.pdf');
      // // Create a URL for the PDF blob
      // const blob = new Blob([response.data], { type: 'application/pdf' });
      // const url = URL.createObjectURL(blob);
      //
      // setPdfUrl(url);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setCurrentStep(VehicleInsuranceFormStepE.OFFER_DOWNLOAD);
    } catch (err) {
      console.error('Error calling fillForeignFormAction:', err);
      toast.error('Nieoczekiwany błąd serwera.');
    } finally {
      setIsLoading((prev) => ({ ...prev, pdfWithOffers: false }));
    }
  };

  const getStepSequence = (vehicleType: VehicleTypeE): VehicleInsuranceFormStepE[] => {
    switch (vehicleType) {
      case VehicleTypeE.CAR:
        return [...vehicleOcCommonSteps, ...vehicleOcCarSteps];
      case VehicleTypeE.MOTORCYCLE:
        return [...vehicleOcCommonSteps, ...vehicleOcMotorcycleSteps];
      case VehicleTypeE.TRUCK:
        return [...vehicleOcCommonSteps, ...vehicleOcTruckSteps];
      default:
        return vehicleOcCommonSteps; // Fallback case
    }
  };

  const stepSequence = getStepSequence(getValues('vehicleType') as VehicleTypeE);

  const vehicleOcStepActions: Partial<Record<VehicleInsuranceFormStepE, () => Promise<void>>> = {
    [VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES]: async () => {
      setIsLoading((prev) => ({ ...prev, govVehicle: true }));
      const formValues = getValues();
      agent.AiOffice.getDataFromGovVehicleHistory({
        plates: formValues.vehiclePlatesNumber,
        vin: formValues.vehicleVin,
        registerDate: formValues.vehicleFirstRegistrationDate!,
      } as IGovVehicleHistoryForm)
        .then((response) => {
          const vehicleDataFromGovHistory = response;
          if (vehicleDataFromGovHistory.vehicle_not_found) {
            setValue('vehicleNotFoundGovVehicleHistory', true);
            return;
          }

          setValue('vehicleBrand', vehicleDataFromGovHistory.brand);
          setValue('vehicleModelGov', vehicleDataFromGovHistory.model);
          setValue('vehicleType', mapVehicleNameFromGovHistory(vehicleDataFromGovHistory.vehicle_type));
          setValue(
            'vehicleEngineCapacity',
            vehicleDataFromGovHistory.engine_capacity.replaceAll(' ', '').replaceAll('cm³', ''),
          );
          setValue('vehicleEnginePower', vehicleDataFromGovHistory.engine_power);
          setValue('vehicleFuelType', mapFuelTypeFromGovHistory(vehicleDataFromGovHistory.fuel_type));
          setValue('vehicleProductionYear', vehicleDataFromGovHistory.year_of_production);
          setValue('vehicleVin', vehicleDataFromGovHistory.vin_number);
          setValue(
            'vehicleCurrentMileage',
            Number.parseInt(vehicleDataFromGovHistory.last_odometer_reading.replaceAll(' ', '') || '0'),
          );
          setValue('vehiclePurchaseYear', vehicleDataFromGovHistory.vehicle_purchase_year);
          setValue('vehicleNumberOfSeats', vehicleDataFromGovHistory.seats);
          setValue('wasVehicleCheckedInGovPage', true);

          const policyExpiryDate = new Date(vehicleDataFromGovHistory.policy_expiry_date);
          policyExpiryDate.setDate(policyExpiryDate.getDate() + 1);
          const newDateString: string = policyExpiryDate.toISOString().split('T')[0];
          setValue('policyStartDate', newDateString);

          if (currentStepRef.current === VehicleInsuranceFormStepE.VEHICLE_TYPE) {
            setTimeout(() => {
              getNextStep(currentStepRef.current);
            }, 50);
          }
          return getVehicleBrands();
        })
        .finally(() => {
          setIsLoading((prev) => ({ ...prev, govVehicle: false }));
        });
    },
    [VehicleInsuranceFormStepE.VEHICLE_PRODUCTION_YEAR]: async () => {
      const models = await getVehicleModels(getValues('vehicleProductionYear'));
      if (models.length === 1) {
        setValue('vehicleModel', models[0].label);
      }
    },
    [VehicleInsuranceFormStepE.VEHICLE_MODEL]: async () => {
      await getFuelTypes();
    },
    [VehicleInsuranceFormStepE.VEHICLE_ENGINE_CAPACITY]: async () => {
      await getVehiclePowers();
    },
    [VehicleInsuranceFormStepE.VEHICLE_ENGINE_POWER]: async () => {
      const gearboxes = await getGearboxes();

      if (gearboxes.length === 1) {
        setValue('vehicleGearboxType', gearboxes[0].label);
      }
    },
    [VehicleInsuranceFormStepE.VEHICLE_GEARBOX_TYPE]: async () => {
      const secondTypes = await getModelSecondTypes();
      if (secondTypes.length === 1) {
        setValue('vehicleEquipmentType', secondTypes[0].label);
      }
    },
    [VehicleInsuranceFormStepE.VEHICLE_SECOND_TYPE]: async () => {
      const modelsInfoExpert = await getModelsInfoExpert();

      if (modelsInfoExpert.length === 1) {
        setValue('vehicleModelAutoExpert', modelsInfoExpert[0].label);
      }
    },
    [VehicleInsuranceFormStepE.VEHICLE_MODEL_AUTO_EXPERT]: async () => {
      const versionsInfoExpert = await getVersionsInfoExpert();

      if (versionsInfoExpert.length === 1) {
        setValue('vehicleVersionAutoExpert', versionsInfoExpert[0].label);

        watchedValues.vehicleVersionAutoExpert = versionsInfoExpert[0].label;
        const miejscaMatch = versionsInfoExpert[0]?.label.match(/l\. miejsc:\s*(\d+)/i);

        if (miejscaMatch) {
          setValue('vehicleNumberOfSeats', Number.parseInt(miejscaMatch[1]));
        }
      }
    },
  };

  const vehicleOcCustomStepOrders: Partial<
    Record<VehicleInsuranceFormStepE, (watchedValues: IAddInsuranceFormValues) => VehicleInsuranceFormStepE | null>
  > = {
    // [VehicleInsuranceFormStepE.WAS_VEHICLE_IMPORTED]: (watchedValues) => {
    //   return !watchedValues.wasVehicleImported ? VehicleInsuranceFormStepE.IS_REGISTERED_AS_TRUCK : null; // If no special case, proceed as usual
    // },
  };

  const checkSkipStep: Partial<Record<VehicleInsuranceFormStepE, () => boolean>> = {
    [VehicleInsuranceFormStepE.IS_STEERING_WHEEL_ON_RIGHT]: () => {
      return !getValues().otherDataSectionVehicleWasImported || getValues('vehicleType') === VehicleTypeE.MOTORCYCLE;
    },
    [VehicleInsuranceFormStepE.IS_REGISTERED_AS_TRUCK]: () => {
      return !!getValues('vehicleType') && getValues('vehicleType') === VehicleTypeE.MOTORCYCLE;
    },
    [VehicleInsuranceFormStepE.VEHICLE_APPROXIMATE_VALUE]: () => {
      return !getValues('policyType').some((type) => type.toLowerCase() === 'ac');
    },
    // OC
    [VehicleInsuranceFormStepE.LAST_INSURED_BY_CURRENT_OWNER]: () => {
      return !getValues().policyType.includes('OC');
    },
    [VehicleInsuranceFormStepE.PREV_INSURED_COMPANY]: () => {
      return !getValues().policyType.includes('OC') || !getValues().ocSectionLastInsuredByCurrentOwner;
    },
    [VehicleInsuranceFormStepE.PREV_OWNER_HAD_OC]: () => {
      return !getValues().policyType.includes('OC') || !!getValues().ocSectionPrevInsuredCompany;
    },
    [VehicleInsuranceFormStepE.PREV_OWNER_OC_INSURED_COMPANY]: () => {
      return !getValues().policyType.includes('OC') || !getValues().ocSectionPrevOwnerHadOc;
    },
    // AC
    [VehicleInsuranceFormStepE.AC_LAST_INSURED_BY_CURRENT_OWNER]: () => {
      return !getValues().policyType.includes('AC');
    },
    [VehicleInsuranceFormStepE.AC_PREV_INSURED_COMPANY]: () => {
      return !getValues().policyType.includes('AC') || !getValues().acSectionLastInsuredByCurrentOwner;
    },
    [VehicleInsuranceFormStepE.AC_PREV_OWNER_HAD_AC]: () => {
      return !getValues().policyType.includes('AC') || !!getValues().acSectionPrevInsuredCompany;
    },
    [VehicleInsuranceFormStepE.AC_PREV_OWNER_AC_INSURED_COMPANY]: () => {
      return !getValues().policyType.includes('AC') || !getValues().acSectionPrevOwnerHadAc;
    },
  };

  const getNextStep = async (currentStep: VehicleInsuranceFormStepE) => {
    const steps = getStepSequence(getValues('vehicleType') as VehicleTypeE);
    let currentIndex = steps.indexOf(currentStep);
    if (currentIndex === -1) return;

    // Validate the current step
    const requiredFields = vehicleOCStepFields[currentStep] || [];
    const isValid = await trigger(requiredFields);
    if (!isValid) return;

    if (vehicleOcStepActions[currentStep]) {
      setIsLoading((prev) => ({ ...prev, basic: true }));
      await vehicleOcStepActions[currentStep]?.();
    }

    const customNextStep = vehicleOcCustomStepOrders[currentStep]?.(getValues());
    if (customNextStep) {
      setCurrentStep(customNextStep);
      setIsLoading((prev) => ({ ...prev, basic: false }));
      return;
    }

    // Loop through next steps until we find an incomplete one
    while (currentIndex < steps.length - 1) {
      currentIndex++;
      const nextStep = steps[currentIndex];

      if (checkSkipStep?.[nextStep]?.()) {
        continue;
      }

      const requiredFields = vehicleOCStepFields[nextStep] || [];
      let hasMissingFields = requiredFields.some((field) => {
        const value = getValues()[field];
        return !value || (Array.isArray(value) && value.length === 0);
      });
      if (!hasMissingFields && vehicleOcStepActions[nextStep]) {
        setIsLoading((prev) => ({ ...prev, basic: true }));
        await vehicleOcStepActions[nextStep]?.();
        hasMissingFields = requiredFields.some((field) => {
          const value = getValues()[field];
          return !value || (Array.isArray(value) && value.length === 0);
        });
      }

      // 🔹 If this step is missing data, stop and set it as the next step
      if (requiredFields.length === 0 || hasMissingFields) {
        setCurrentStep(nextStep);
        setIsLoading((prev) => ({ ...prev, basic: false }));
        return;
      }
    }

    // Default to the last step if all fields are filled
    setCurrentStep(VehicleInsuranceFormStepE.OWNER_POST_CODE);
  };

  const renderOptionsForYesNoSelectBox = (option: MySelectInputOptions) =>
    option.value ? (
      <div className={'tw-flex tw-items-center tw-gap-2 '}>
        <ThumbsUp /> {option.label}
      </div>
    ) : (
      <div className={'tw-flex tw-items-center tw-gap-2 '}>
        <ThumbsDown /> {option.label}
      </div>
    );

  const handleNextStep = async () => {
    getNextStep(currentStep);
  };

  const wizardSteps = [
    {
      step: VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES,
      render: () => (
        <>
          <div className={'tw-mb-2 tw-text-xl tw-font-bold tw-text-nau-kids-gray'}>Dane podstawowe</div>

          <div className='tw-mt-4 tw-flex tw-max-w-lg tw-items-center tw-gap-6 tw-rounded-2xl tw-border  tw-bg-white tw-p-2  tw-transition-all '>
            <div className='tw-flex-1'>
              <Bot />
              <p className='tw-mt-1 tw-text-sm tw-text-gray-600'>Rozpoznamy dane zamiast Ciebie.</p>
            </div>
            <button
              className='tw-flex tw-items-center tw-gap-2 tw-rounded-xl tw-bg-gradient-to-r tw-from-blue-500 tw-to-indigo-600 tw-px-5 tw-py-3 tw-font-medium tw-text-white tw-shadow-lg tw-transition-all hover:tw-shadow-xl hover:tw-brightness-110'
              type={'button'}>
              <span>Zrób zdjęcie dowodu</span>
            </button>
          </div>
          <div className={'tw-mb-2 tw-mt-8 tw-text-center'}>Lub podaj dane ręcznie</div>
          <div className='tw-flex tw-flex-1 tw-flex-col tw-gap-2'>
            <MyTextInputRHF
              error={errors.vehiclePlatesNumber?.message}
              label='Numer rejestracyjny'
              name='vehiclePlatesNumber'
              onChange={(event) => {
                setValue('vehiclePlatesNumber', event.target.value.toUpperCase().replaceAll(' ', ''));
              }}
              register={register}
            />
            <MyTextInputRHF
              error={errors.vehicleFirstRegistrationDate?.message}
              label='Data pierwszej rejestracji'
              name='vehicleFirstRegistrationDate'
              register={register}
              type={'date'}
            />
            <MyTextInputRHF
              error={errors.vehicleVin?.message}
              label='VIN'
              name='vehicleVin'
              onChange={(event) => {
                setValue('vehicleVin', event.target.value.toUpperCase().replaceAll(' ', ''));
              }}
              register={register}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_TYPE,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            <div>Potrzebujemy kilku informacji więcej.</div>
          </div>

          {isLoading.govVehicle ? (
            <LoadingCarBackdrop isLoading={true} texts={['Zbieranie danych pojazdu...']} />
          ) : (
            <>
              <MySelectBoxInput
                error={errors.vehicleType?.message}
                label='Typ pojazdu'
                name='vehicleType'
                onChange={(value) => {
                  setValue('vehicleType', value);
                  handleNextStep();
                }}
                options={vehicleTypeDropdownOptions}
                value={watchedValues.vehicleType}
              />
            </>
          )}
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.POLICY_TYPE,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            <div>Potrzebujemy kilku informacji więcej.</div>
          </div>

          <MyMultiSelectBoxInput
            error={errors.policyType?.message}
            itemClassName={'tw-w-full tw-aspect-square tw-text-3xl'}
            label='Rodzaj ubezpieczenia'
            name='policyType'
            onChange={(value) => {
              setValue('policyType', value);
            }}
            options={policyTypeOptions}
            renderOption={(option) =>
              option.label == 'OC' ? (
                <div className={'tw-flex tw-items-center tw-gap-2 '}>
                  <div>
                    {option.label}
                    <div className={'tw-mt-2 tw-text-xs'}>Ubezpieczenie</div>
                    <div className={' tw-text-xs'}>obowiązkowe</div>
                  </div>
                </div>
              ) : (
                <div className={'tw-flex tw-items-center tw-gap-2 '}>
                  <div>
                    {option.label}
                    <div className={'tw-mt-2 tw-text-xs'}>Ubezpieczenie</div>
                    <div className={' tw-text-xs'}>dobrowolne</div>
                  </div>
                </div>
              )
            }
            value={watchedValues.policyType}
            withCheckIcon={true}
            wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2 tw-max-w-sm tw-mx-auto '}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_BRAND,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            <div>Potrzebujemy kilku informacji więcej.</div>
          </div>

          <MySelectBoxInput
            error={errors.vehicleBrand?.message}
            label='Marka pojazdu'
            name='vehicleBrand'
            onChange={(value) => {
              setValue('vehicleBrand', value);
              handleNextStep();
            }}
            options={nau24dropdownOptions.current.brand.map((model) => ({
              label: model.label,
              value: model.label,
            }))}
            value={watchedValues.vehicleBrand}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_PRODUCTION_YEAR,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze chwila i finisz!
          </div>
          <MyTextInputRHF
            error={errors.vehicleProductionYear?.message}
            label='Rok produkcji'
            name='vehicleProductionYear'
            register={register}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_PURCHASE_YEAR,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze chwila i finisz!
          </div>
          <MyTextInputRHF
            error={errors.vehiclePurchaseYear?.message}
            label='Rok zakupu'
            name='vehiclePurchaseYear'
            register={register}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_MODEL,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            <div>Potrzebujemy kilku informacji więcej.</div>
          </div>

          <MySelectBoxInput
            error={errors.vehicleModel?.message}
            label='Model'
            name='vehicleModel'
            onChange={(value) => {
              setValue('vehicleModel', value);
              handleNextStep();
            }}
            options={nau24dropdownOptions.current.model.map((model) => ({ label: model.label, value: model.label }))}
            value={watchedValues.vehicleModel}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_FUEL_TYPE,
      render: () => (
        <MySelectBoxInput
          error={errors.vehicleFuelType?.message}
          label='Rodzaj paliwa'
          name='vehicleFuelType'
          onChange={(value) => {
            setValue('vehicleFuelType', value);
            handleNextStep();
          }}
          options={nau24dropdownOptions.current.fuel.map((gearbox) => ({
            label: gearbox.label,
            value: gearbox.label,
          }))}
          value={watchedValues.vehicleFuelType}
        />
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_ENGINE_CAPACITY,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze chwila i finisz!
          </div>
          <MyTextInputRHF
            error={errors.vehicleEngineCapacity?.message}
            label='Pojemność silnika (cm3)'
            name='vehicleEngineCapacity'
            register={register}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_ENGINE_POWER,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze chwila i finisz!
          </div>
          <MyTextInputRHF
            error={errors.vehicleEnginePower?.message}
            label='Moc silnika (kw)'
            name='vehicleEnginePower'
            register={register}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_GEARBOX_TYPE,
      render: () => (
        <MySelectBoxInput
          error={errors.vehicleGearboxType?.message}
          label='Skrzynia biegów'
          name='vehicleGearboxType'
          onChange={(value) => {
            setValue('vehicleGearboxType', value);
            handleNextStep();
          }}
          options={nau24dropdownOptions.current.gearbox.map((gearbox) => ({
            label: gearbox.label,
            value: gearbox.label,
          }))}
          value={watchedValues.vehicleGearboxType}
        />
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_SECOND_TYPE,
      render: () => (
        <MySelectBoxInput
          error={errors.vehicleEquipmentType?.message}
          label='Typ'
          name='vehicleEquipmentType'
          onChange={(value) => {
            setValue('vehicleEquipmentType', value);
            handleNextStep();
          }}
          options={nau24dropdownOptions.current.secondType.map((value) => ({
            label: value.label,
            value: value.label,
          }))}
          value={watchedValues.vehicleEquipmentType}
        />
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_MODEL_AUTO_EXPERT,
      render: () => (
        <MySelectBoxInput
          error={errors.vehicleModelAutoExpert?.message}
          label='Model AutoExpert'
          name='vehicleModelAutoExpert'
          onChange={(value) => {
            setValue('vehicleModelAutoExpert', value);
            handleNextStep();
          }}
          options={nau24dropdownOptions.current.modelAutoExpert.map((value) => ({
            label: value.label,
            value: value.label,
          }))}
          value={watchedValues.vehicleModelAutoExpert}
        />
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_VERSION_AUTO_EXPERT,
      render: () => (
        <MySelectBoxInput
          error={errors.vehicleVersionAutoExpert?.message}
          label='Wersja AutoExpert'
          name='vehicleVersionAutoExpert'
          onChange={(value) => {
            setValue('vehicleVersionAutoExpert', value);
            handleNextStep();
          }}
          options={nau24dropdownOptions.current.versionAutoExpert.map((value) => ({
            label: value.label,
            value: value.label,
          }))}
          value={watchedValues.vehicleVersionAutoExpert}
        />
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_NUMBER_OF_SEATS,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze chwila i finisz!
          </div>
          <MyTextInputRHF
            error={errors.vehicleNumberOfSeats?.message}
            label='Liczba miejsc'
            name='vehicleNumberOfSeats'
            register={register}
            type={'number'}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_CURRENT_MILEAGE,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze chwila i finisz!
          </div>
          <MyTextInputRHF
            error={errors.vehicleCurrentMileage?.message}
            label='Aktualny przebieg [km]'
            name='vehicleCurrentMileage'
            register={register}
            type={'number'}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.VEHICLE_APPROXIMATE_VALUE,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze chwila i finisz!
          </div>
          <MyTextInputRHF
            error={errors.vehicleApproximateValue?.message}
            label='Orientacyjna wartość pojazdu [zł]'
            name='vehicleApproximateValue'
            // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            //   const newValue = e.target.value.replace(/\D/g, '');
            //   setValue('vehicleApproximateValue', newValue);
            // }}
            onInput={(event) => {
              // Remove any non-numeric characters, including decimals
              const input = event.target as HTMLInputElement;
              input.value = input.value.replace(/[^0-9]/g, '');
            }}
            onKeyDown={(event) => {
              // Allow control keys (Backspace, Delete, Arrow keys, Tab)
              if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
                return;
              }

              // Prevent non-numeric keys
              if (!/^[0-9]$/.test(event.key)) {
                event.preventDefault();
              }
            }}
            register={register}
            step={1}
            type={'number'}
            value={watchedValues.vehicleApproximateValue}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.ESTIMATED_ANNUAL_MILEAGE,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jesteś na dobrej drodze! Jeszcze chwila i gotowe.
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.otherDataSectionVehicleEstimatedAnnualMileage?.message}
              itemClassName={'tw-w-full'}
              label='Przewidywany roczny przebieg'
              name='otherDataSectionVehicleEstimatedAnnualMileage'
              onChange={(value) => {
                setValue('otherDataSectionVehicleEstimatedAnnualMileage', value);
                handleNextStep();
              }}
              options={[
                {
                  label: 'poniżej 5 000 km',
                  value: 'poniżej 5 000 km',
                },
                {
                  label: '5 000 - 10 000 km',
                  value: '5 000 - 10 000 km',
                },
                {
                  label: '10 001 - 15 000 km',
                  value: '10 001 - 15 000 km',
                },
                {
                  label: '15 001 - 20 000 km',
                  value: '15 001 - 20 000 km',
                },
                {
                  label: '20 001 - 30 000 km',
                  value: '20 001 - 30 000 km',
                },
                {
                  label: '30 001 - 40 000 km',
                  value: '30 001 - 40 000 km',
                },
                {
                  label: '40 001 - 50 000 km',
                  value: '40 001 - 50 000 km',
                },
                {
                  label: '50 001 - 60 000 km',
                  value: '50 001 - 60 000 km',
                },
                {
                  label: '60 001 - 70 000 km',
                  value: '60 001 - 70 000 km',
                },
                {
                  label: 'powyżej 70 000 km',
                  value: 'powyżej 70 000 km',
                },
              ]}
              value={watchedValues.otherDataSectionVehicleEstimatedAnnualMileage}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.PARKING_PLACE,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze kilka kroków do mety.
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.otherDataSectionParkingPlaceAtNight?.message}
              itemClassName={'tw-w-full'}
              label='Miejsce parkowania w nocy'
              name='otherDataSectionParkingPlaceAtNight'
              onChange={(value) => {
                setValue('otherDataSectionParkingPlaceAtNight', value);
                handleNextStep();
              }}
              options={[
                { label: 'Ulica', value: 'ulica' },
                { label: 'Garaż indywidualny', value: 'garaż indywidualny' },
                { label: 'Garaż wspólny', value: 'garaż wspólny' },
                { label: 'Parking niestrzeżony', value: 'parking niestrzeżony' },
                { label: 'Parking strzeżony', value: 'parking strzeżony' },
              ]}
              value={watchedValues.otherDataSectionParkingPlaceAtNight}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },

    {
      step: VehicleInsuranceFormStepE.ESTIMATED_USED_ABROAD,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Potrzebujemy tylko kilku szczegółów więcej.
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.otherDataSectionVehicleEstimatedUsedAbroad?.message}
              itemClassName={'tw-w-full'}
              label='Jak długo pojazd będzie używany za granicą?'
              name='otherDataSectionVehicleEstimatedUsedAbroad'
              onChange={(value) => {
                setValue('otherDataSectionVehicleEstimatedUsedAbroad', value);
                handleNextStep();
              }}
              options={[
                {
                  label: 'nie będzie',
                  value: 'nie będzie',
                },
                {
                  label: 'do 1 miesiąca',
                  value: 'do 1 miesiąca',
                },
                {
                  label: 'od 1 do 2 miesięcy',
                  value: 'od 1 do 2 miesięcy',
                },
                {
                  label: 'od 2 do 3 miesięcy',
                  value: 'od 2 do 3 miesięcy',
                },
                {
                  label: 'od 3 do 6 miesięcy',
                  value: 'od 3 do 6 miesięcy',
                },
                {
                  label: 'powyżej 6 miesięcy',
                  value: 'powyżej 6 miesięcy',
                },
              ]}
              value={watchedValues.otherDataSectionVehicleEstimatedUsedAbroad}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.WAS_VEHICLE_IMPORTED,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze moment i mamy to!
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.otherDataSectionVehicleWasImported?.message}
              itemClassName={'tw-w-full'}
              label='Czy pojazd był sprowadzany?'
              name='otherDataSectionVehicleWasImported'
              onChange={(value) => {
                setValue('otherDataSectionVehicleWasImported', value);
                handleNextStep();
              }}
              options={noyesOptions}
              renderOption={renderOptionsForYesNoSelectBox}
              value={watchedValues.otherDataSectionVehicleWasImported}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.IS_STEERING_WHEEL_ON_RIGHT,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Teraz tylko kilka ostatnich kliknięć!
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.otherDataSectionVehicleSteeringWheelOnRightSide?.message}
              itemClassName={'tw-w-full'}
              label='Czy kierownica jest po prawej stronie?'
              name='otherDataSectionVehicleSteeringWheelOnRightSide'
              onChange={(value) => {
                setValue('otherDataSectionVehicleSteeringWheelOnRightSide', value);
                handleNextStep();
              }}
              options={noyesOptions}
              renderOption={renderOptionsForYesNoSelectBox}
              value={watchedValues.otherDataSectionVehicleSteeringWheelOnRightSide}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.IS_REGISTERED_AS_TRUCK,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze chwila i finisz!
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.otherDataSectionVehicleRegisteredAsTruck?.message}
              itemClassName={'tw-w-full'}
              label='Czy pojazd jest zarejestrowany jako ciężarowy?'
              name='otherDataSectionVehicleRegisteredAsTruck'
              onChange={(value) => {
                setValue('otherDataSectionVehicleRegisteredAsTruck', value);
                handleNextStep();
              }}
              options={noyesOptions}
              renderOption={renderOptionsForYesNoSelectBox}
              value={watchedValues.otherDataSectionVehicleRegisteredAsTruck}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.POLICY_START_DATE,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze chwila i finisz!
          </div>
          <MyTextInputRHF
            error={errors.policyStartDate?.message}
            label='Data rozpoczęcia polisy'
            name='policyStartDate'
            register={register}
            type={'date'}
          />
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.LAST_INSURED_BY_CURRENT_OWNER,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Zostało tylko kilka drobiazgów!
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.ocSectionLastInsuredByCurrentOwner?.message}
              itemClassName={'tw-w-full'}
              label='Czy pojazd był ubezpieczony w ubiegłym roku przez obecnego właściciela?
'
              name='ocSectionLastInsuredByCurrentOwner'
              onChange={(value) => {
                setValue('ocSectionLastInsuredByCurrentOwner', value);
                handleNextStep();
              }}
              options={yesnoOptions}
              renderOption={renderOptionsForYesNoSelectBox}
              value={watchedValues.ocSectionLastInsuredByCurrentOwner}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.PREV_INSURED_COMPANY,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Potrzebujemy kilku informacji więcej.
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.ocSectionPrevInsuredCompany?.message}
              itemClassName={'tw-w-full'}
              label='Zakład ubezpieczeń, w którym właściciel posiadał ostatnią polisę ubezpieczeniową OC'
              name='ocSectionPrevInsuredCompany'
              onChange={(value) => {
                setValue('ocSectionPrevInsuredCompany', value);
                handleNextStep();
              }}
              options={insuranceCompaniesOptions}
              value={watchedValues.ocSectionPrevInsuredCompany}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.PREV_OWNER_HAD_OC,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Potrzebujemy kilku informacji więcej.
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.ocSectionPrevOwnerHadOc?.message}
              itemClassName={'tw-w-full'}
              label='Czy którykolwiek z właścicieli posiadał w ostatnich 5-ciu latach ubezpieczenie komunikacyjne OC?'
              name='ocSectionPrevOwnerHadOc'
              onChange={(value) => {
                setValue('ocSectionPrevOwnerHadOc', value);
                handleNextStep();
              }}
              options={yesnoOptions}
              renderOption={renderOptionsForYesNoSelectBox}
              value={watchedValues.ocSectionPrevOwnerHadOc}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },

    {
      step: VehicleInsuranceFormStepE.PREV_OWNER_OC_INSURED_COMPANY,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Potrzebujemy kilku informacji więcej.
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.ocSectionPrevOwnerOcInsuredCompany?.message}
              itemClassName={'tw-w-full'}
              label='Zakład ubezpieczeń, w którym właściciel posiadał ostatnią polisę ubezpieczeniową OC'
              name='ocSectionPrevOwnerOcInsuredCompany'
              onChange={(value) => {
                setValue('ocSectionPrevOwnerOcInsuredCompany', value);
                handleNextStep();
              }}
              options={insuranceCompaniesOptions}
              value={watchedValues.ocSectionPrevOwnerOcInsuredCompany}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.AC_LAST_INSURED_BY_CURRENT_OWNER,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Zostało tylko kilka drobiazgów!
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.acSectionLastInsuredByCurrentOwner?.message}
              itemClassName={'tw-w-full'}
              label='Czy pojazd był ubezpieczony w ubiegłym roku przez obecnego właściciela (AC)?
'
              name='acSectionLastInsuredByCurrentOwner'
              onChange={(value) => {
                setValue('acSectionLastInsuredByCurrentOwner', value);
                handleNextStep();
              }}
              options={yesnoOptions}
              renderOption={renderOptionsForYesNoSelectBox}
              value={watchedValues.acSectionLastInsuredByCurrentOwner}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.AC_PREV_INSURED_COMPANY,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Potrzebujemy kilku informacji więcej.
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.acSectionPrevInsuredCompany?.message}
              itemClassName={'tw-w-full'}
              label='Zakład ubezpieczeń, w którym właściciel posiadał ostatnią polisę ubezpieczeniową AC'
              name='acSectionPrevInsuredCompany'
              onChange={(value) => {
                setValue('acSectionPrevInsuredCompany', value);
                handleNextStep();
              }}
              options={insuranceCompaniesOptions}
              value={watchedValues.acSectionPrevInsuredCompany}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.AC_PREV_OWNER_HAD_AC,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Potrzebujemy kilku informacji więcej.
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.acSectionPrevOwnerHadAc?.message}
              itemClassName={'tw-w-full'}
              label='Czy którykolwiek z właścicieli posiadał w ostatnich 5-ciu latach ubezpieczenie komunikacyjne AC?'
              name='acSectionPrevOwnerHadAc'
              onChange={(value) => {
                setValue('acSectionPrevOwnerHadAc', value);
                handleNextStep();
              }}
              options={yesnoOptions}
              renderOption={renderOptionsForYesNoSelectBox}
              value={watchedValues.acSectionPrevOwnerHadAc}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },

    {
      step: VehicleInsuranceFormStepE.AC_PREV_OWNER_AC_INSURED_COMPANY,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Potrzebujemy kilku informacji więcej.
          </div>
          <div className={'tw-grid tw-grid-cols-1 tw-gap-2'}>
            <MySelectBoxInput
              error={errors.acSectionPrevOwnerAcInsuredCompany?.message}
              itemClassName={'tw-w-full'}
              label='Zakład ubezpieczeń, w którym właściciel posiadał ostatnią polisę ubezpieczeniową AC'
              name='acSectionPrevOwnerAcInsuredCompany'
              onChange={(value) => {
                setValue('acSectionPrevOwnerAcInsuredCompany', value);
                handleNextStep();
              }}
              options={insuranceCompaniesOptions}
              value={watchedValues.acSectionPrevOwnerAcInsuredCompany}
              wrapperClassName={'tw-grid tw-gap-4 tw-grid-cols-2'}
            />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.OWNER_PESEL,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>Już prawie gotowe!</div>
          <div className='tw-flex tw-flex-1 tw-flex-col tw-gap-2'>
            <MyTextInputRHF error={errors.pesel?.message} label='PESEL' name='pesel' register={register} />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.OWNER_FIRST_NAME,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>Już prawie gotowe!</div>
          <div className='tw-flex tw-flex-1 tw-flex-col tw-gap-2'>
            <MyTextInputRHF error={errors.firstName?.message} label='Imię' name='firstName' register={register} />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.OWNER_LAST_NAME,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>Już prawie gotowe!</div>
          <div className='tw-flex tw-flex-1 tw-flex-col tw-gap-2'>
            <MyTextInputRHF error={errors.lastName?.message} label='Nazwisko' name='lastName' register={register} />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.OWNER_EMAIL,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>Jesteśmy już blisko!</div>
          <div className='tw-flex tw-flex-1 tw-flex-col tw-gap-2'>
            <MyTextInputRHF error={errors.email?.message} label='Email' name='email' register={register} />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.OWNER_PHONE_NUMBER,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>Jesteśmy już blisko!</div>
          <div className='tw-flex tw-flex-1 tw-flex-col tw-gap-2'>
            <MyTextInputRHF error={errors.phone?.message} label='Telefon' name='phone' register={register} />
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.OWNER_POST_CODE,
      render: () => (
        <>
          <div className={' tw-mb-4 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-sea-green'}>
            Jeszcze jeden krok i koniec!
          </div>
          <div className='tw-flex tw-flex-1 tw-flex-col tw-gap-2'>
            <AddressPromptingFormRHF
              cityFormikName={'ownerSectionCity'}
              flatNoFormikName={'flat'}
              houseNoFormikName={'house'}
              postCodeFormikName={'ownerSectionPostCode'}
              streetFormikName={'street'}
            />
            {/* <MyTextInputRHF */}
            {/*   error={errors.ownerSectionPostCode?.message} */}
            {/*   label='Kod pocztowy' */}
            {/*   mask={MaskHelpers.postCode} */}
            {/*   name='ownerSectionPostCode' */}
            {/*   register={register} */}
            {/* /> */}
          </div>
        </>
      ),
    },
    {
      step: VehicleInsuranceFormStepE.SUMMARY,
      render: () => (
        <>
          <div
            className={
              'tw-mb-6 tw-mt-2 tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-center tw-text-2xl tw-font-bold tw-text-nau-kids-gray '
            }>
            Podsumowanie <Database className={'tw-inline-block'} />
          </div>
          <div className={'tw-mb-4 tw-flex tw-justify-center'}>
            <MyButton isLoading={isSubmitting} type='submit' variant='primary'>
              <div>
                <div className={'tw-text-xs'}>Potwierdzam dane</div>
                <div>Wykonaj kalkulacje w towarzystwach</div>
              </div>
            </MyButton>
          </div>

          <VehicleInsuranceSummary
            onEdit={(step: VehicleInsuranceFormStepE) => {
              setCurrentStep(step);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            values={watchedValues}
          />
          <div className={'tw-mt-4 tw-flex tw-justify-center'}>
            <MyButton isLoading={isSubmitting} type='submit' variant='primary'>
              <div>
                <div className={'tw-text-xs'}>Potwierdzam dane</div>
                <div>Wykonaj kalkulacje w towarzystwach</div>
              </div>
            </MyButton>
          </div>
        </>
      ),
    },

    {
      step: VehicleInsuranceFormStepE.OFFER_DOWNLOAD,
      render: () => (
        <>
          {/* <div className='flex flex-col items-center justify-center  text-center'> */}
          {/*   <div className='bg-white p-2 rounded-2xl '> */}
          {/*     <h1 className='text-3xl font-bold text-green-600'>Dziękujemy!</h1> */}
          {/*     <p className='text-lg text-gray-700 mt-2 mb-4'>Oto twoja oferta.</p> */}
          {/*     {pdfUrl && <iframe className='tw-h-[600px] tw-w-full tw-border tw-border-gray-300' src={pdfUrl} />} */}
          {/*   </div> */}
          {/* </div> */}
          {calculationResult && <VehicleInsuranceOffers data={calculationResult} />}
        </>
      ),
    },
  ];

  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  return (
    <div className={'tw-mx-auto tw-mt-8 tw-max-w-3xl tw-rounded-xl tw-bg-white '}>
      <div className={' tw-fixed tw-left-0  tw-top-1/4 tw-hidden tw-w-[200px] lg:tw-block'}>
        <div className='tw-mx-auto tw-mt-8 tw-max-w-lg tw-rounded-2xl tw-bg-nau-sea-green tw-px-4 tw-py-6 tw-text-center tw-text-white tw-shadow-lg'>
          <h2 className='tw-mb-2 tw-text-2xl tw-font-bold'>
            Z <span className='tw-text-yellow-300'>NAU OC</span>
            <div>bez stresu i w dobrej cenie!</div>
          </h2>
          <img alt={'car'} className={'tw-w-full tw-rounded-xl'} src={'/assets/vehicleInsurance/insurance_car.jpg'} />
        </div>
      </div>
      <div className={' tw-fixed tw-right-0  tw-top-1/4 tw-hidden tw-w-[200px] lg:tw-block'}>
        <div className='tw-mx-auto tw-mt-8 tw-max-w-lg tw-rounded-2xl tw-bg-nau-green-light tw-px-4 tw-py-6 tw-text-center tw-text-white tw-shadow-lg'>
          <h2 className='tw-mb-2 tw-text-2xl tw-font-bold'>
            Z <span className='tw-text-yellow-300'>NAU OC</span>
            <div>zero zmartwień,</div>
            <div>zero strachu!</div>
          </h2>
          <img alt={'car'} className={'tw-w-full tw-rounded-xl'} src={'/assets/vehicleInsurance/insurance_car_2.jpg'} />
        </div>
      </div>
      <SubpageHeader
        headerClassName={twMerge('tw-text-xl lg:tw-text-2xl')}
        title={() => (
          <div>
            <div className={'tw-flex tw-justify-center'}>
              <Car className={' tw-h-10 tw-w-10'} />
            </div>
            <div className={'tw-flex tw-items-center tw-justify-center tw-gap-2'}>Wyszukiwarka ofert OC/AC</div>
            <AnimatePresence>
              {currentStep != VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES && (
                <motion.div animate={{ opacity: 1 }} className='' exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
                  <div className={'tw-mt-2 tw-text-sm tw-font-normal'}>Najlepsza oferta jest już blisko...</div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      />
      <LoadingCarBackdrop
        isLoading={isLoading.pdfWithOffers}
        texts={[
          ...Array.from({ length: 10 }, (_, i) => `Zbieranie danych towarzystwa ${i + 1}`),
          ...Array(100)
            .fill([
              'Przetwarzanie informacji...',
              'Dobieranie najlepszych ofert...',
              'Oczekiwanie na odpowiedź...',
              'Analiza ubezpieczenia...',
              'Weryfikacja danych...',
            ])
            .flat(),
        ]}
      />
      <LoadingCarBackdrop isLoading={isLoading.basic} texts={['Zbieranie danych...']} />

      <FormProvider {...methods}>
        <form
          className={twMerge(
            'tw-mx-auto tw-flex tw-max-w-lg tw-flex-col tw-gap-2 tw-p-4 ',
            currentStep === VehicleInsuranceFormStepE.OFFER_DOWNLOAD && 'tw-max-w-2xl',
          )}
          onSubmit={handleSubmit(onSubmit)}>
          <AnimatePresence mode={'popLayout'}>
            <motion.div
              animate={{
                opacity: 1,
                x: 0,
              }}
              exit={{
                opacity: 0,
                x: -100,
              }}
              initial={{
                opacity: 0,
                x: 100,
              }}
              key={currentStep}>
              {wizardSteps.find((el) => el.step === currentStep)?.render()}
            </motion.div>
          </AnimatePresence>

          {/* Navigation buttons */}
          <div className='tw-mt-4 tw-flex tw-justify-between tw-gap-2'>
            <div>
              {stepSequence.indexOf(currentStep) > 0 && stepSequence.indexOf(currentStep) < stepSequence.length - 1 && (
                <FancyButton
                  onClick={() => setCurrentStep(stepSequence[stepSequence.indexOf(currentStep) - 1])}
                  variant={'secondary'}>
                  Cofnij
                </FancyButton>
              )}
            </div>
            <div className={'tw-flex tw-justify-between'}>
              {[
                VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES,
                VehicleInsuranceFormStepE.POLICY_TYPE,
                VehicleInsuranceFormStepE.OWNER_PESEL,
                VehicleInsuranceFormStepE.OWNER_FIRST_NAME,
                VehicleInsuranceFormStepE.OWNER_LAST_NAME,
                VehicleInsuranceFormStepE.OWNER_POST_CODE,
                VehicleInsuranceFormStepE.POLICY_START_DATE,
                VehicleInsuranceFormStepE.VEHICLE_PRODUCTION_YEAR,
                VehicleInsuranceFormStepE.VEHICLE_PURCHASE_YEAR,
                VehicleInsuranceFormStepE.VEHICLE_ENGINE_CAPACITY,
                VehicleInsuranceFormStepE.VEHICLE_ENGINE_POWER,
                VehicleInsuranceFormStepE.VEHICLE_NUMBER_OF_SEATS,
                VehicleInsuranceFormStepE.VEHICLE_CURRENT_MILEAGE,
                VehicleInsuranceFormStepE.VEHICLE_APPROXIMATE_VALUE,
              ].includes(currentStep) && <FancyButton onClick={() => handleNextStep()}>Dalej</FancyButton>}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
