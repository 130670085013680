import { observer } from 'mobx-react-lite';
import React from 'react';
import * as styles from './styles';
import CinemaVoucherHeader from '../CinemaVoucherHeader/CinemaVoucherHeader';
import { Button } from 'react-bootstrap';
import { PathRoute } from '../../../constants/pathRoute/Route';

const CinemaVoucherWinterBreakInfo: React.FC = () => {
  return (
    <div className='tw-p-6'>
      <div style={{ width: '100%' }}>
        <img
          alt=''
          src='https://multimedia.prima.nau.pl/nau-B/photos/f062b185-b756-4a3f-ac4c-9f84a990c2fe.png'
          style={{
            border: '0',
            display: 'block',
            outline: 'none',
            textDecoration: 'none',
            height: 'auto',
            width: '100%',
            fontSize: '13px',
          }}
          width='595'
          height='auto'
        />
      </div>
      <div style={{ width: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <p
            style={{
              fontSize: '14px',
              marginTop: '0px',
              marginBottom: '0px',
              fontWeight: 'normal',
              color: '#000000',
            }}>
            <span style={{ fontSize: '18px' }}>Ferie zimowe to idealny czas na wspólne chwile w kinie!</span>
          </p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p
            style={{
              fontSize: '14px',
              marginTop: '0px',
              marginBottom: '0px',
              fontWeight: 'normal',
              color: '#000000',
            }}>
            <br />
          </p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p
            style={{
              fontSize: '14px',
              marginTop: '0px',
              marginBottom: '0px',
              fontWeight: 'normal',
              color: '#000000',
            }}>
            <span style={{ fontSize: '18px' }}>Skorzystaj z wyjątkowej oferty i obejrzyj najlepsze filmy</span>
          </p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p
            style={{
              fontSize: '14px',
              marginTop: '0px',
              marginBottom: '0px',
              fontWeight: 'normal',
              color: '#000000',
            }}>
            <span style={{ fontSize: '18px' }}>w Cinema City!</span>
          </p>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <img
          alt=''
          src='https://multimedia.prima.nau.pl/nau-B/photos/4647d150-6378-457c-956c-55599bdd30bd.png'
          style={{
            border: '0',
            display: 'block',
            outline: 'none',
            textDecoration: 'none',
            height: 'auto',
            width: '100%',
            fontSize: '13px',
          }}
          width='590'
          height='auto'
        />
      </div>
      <div
        style={{
          fontSize: '13px',
          lineHeight: '1.4',
          textAlign: 'left',
          color: '#000000',
        }}>
        <p
          style={{
            fontSize: '14px',
            marginTop: '0px',
            marginBottom: '0px',
            fontWeight: 'normal',
            color: '#000000',
          }}>
          <span style={{ color: '#0bc268' }}>
            <div>
              <span style={{ fontSize: '18px', fontWeight: '700' }}>Jak skorzystać z promocji?</span>
            </div>
          </span>
        </p>
        <ul style={{ listStyleType: 'disc' }}>
          <li style={{ fontSize: '16px' }}>
            <p
              style={{
                fontSize: '14px',
                marginTop: '0px',
                marginBottom: '0px',
                fontWeight: 'normal',
                color: '#000000',
              }}>
              <span style={{ fontSize: '16px' }}>Wybierz 3 takie same bilety do Cinema City.</span>
            </p>
          </li>
          <li style={{ fontSize: '16px' }}>
            <p
              style={{
                fontSize: '14px',
                marginTop: '0px',
                marginBottom: '0px',
                fontWeight: 'normal',
                color: '#000000',
              }}>
              <span style={{ fontSize: '16px' }}>
                Wpisz hasło{' '}
                <span
                  style={{
                    fontWeight: '700',
                    color: '#0bc268',
                  }}>
                  "ferie"
                </span>
                .
              </span>
            </p>
          </li>
          <li style={{ fontSize: '16px' }}>
            <p
              style={{
                fontSize: '14px',
                marginTop: '0px',
                marginBottom: '0px',
                fontWeight: 'normal',
                color: '#000000',
              }}>
              <span style={{ fontSize: '16px' }}>Kliknij Zastosuj i zapłać za dwa bilety.</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default observer(CinemaVoucherWinterBreakInfo);
