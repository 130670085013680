import { observer } from 'mobx-react-lite';
import * as React from 'react';
import * as styles from './styles';
import { Button, Image, Spinner } from 'react-bootstrap';
import CinemaVoucherHeader from '../CinemaVoucherHeader/CinemaVoucherHeader';
import CinemaVoucherRateOrder from './CinemaVoucherRateOrder';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';

export default observer((props: { fromPayment?: boolean; paymentOrderId: number }) => {
  const { cinemaVoucherStore } = useStore();
  const [showRating, setShowRating] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (!isReady) {
      timer = setInterval(() => {
        checkCinemaVoucherOrederStatus(props.paymentOrderId).then((resp) => {
          if (resp) {
            setIsReady(true);
          } else {
            setIsReady(false);
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isReady]);

  const checkCinemaVoucherOrederStatus = (paymentOrderId: number) => {
    return cinemaVoucherStore.checkCinemaVoucherOrderStatus(paymentOrderId);
  };
  return (
    <div className='col-md-6 offset-md-3' style={styles.container as React.CSSProperties}>
      <CinemaVoucherHeader />

      {showRating ? (
        <CinemaVoucherRateOrder />
      ) : (
        <>
          <div style={styles.flexColumnPadding}>
            <p style={styles.wayStyles as React.CSSProperties}>Dziękujemy za zakup!</p>
            <p className={'tw-text-center tw-text-2xl tw-text-nau-green-dark'}>
              Kupony znajdziesz w sekcji „Moje kupony”, a także w wiadomości e-mail.
            </p>
            <h2 style={styles.headingStyles}>Udanego seansu!</h2>
          </div>
          <div style={styles.flexCenter as React.CSSProperties}>
            <Image src='assets/popcorn.svg' />
          </div>
          <div className={'tw-mt-10 tw-flex tw-justify-center tw-px-[15px]'}>
            {isReady ? (
              <Button onClick={() => setShowRating(true)} style={styles.width as React.CSSProperties}>
                Oceń proces zakupu
              </Button>
            ) : (
              <Button disabled={true} style={styles.width as React.CSSProperties}>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Oceń proces zakupu...
              </Button>
            )}
          </div>
          <div className={' tw-flex tw-justify-center tw-p-[15px]'}>
            {isReady ? (
              <Button
                onClick={() =>
                  window.open(
                    props.fromPayment
                      ? `${PathRoute.APP_CINEMA_VOUCHER}?vouchersView=true`
                      : `${PathRoute.CINEMA_VOUCHER}?vouchersView=true`,
                    '_self',
                    'location=no',
                  )
                }
                style={styles.width as React.CSSProperties}>
                Przejdź do "Moje kupony"
              </Button>
            ) : (
              <Button disabled={true} style={styles.width as React.CSSProperties}>
                <span className='m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Przetwarzanie zamówienia...
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
});
