import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { BuildingOfficeIcon, TruckIcon } from '@heroicons/react/24/outline';

interface LoadingBackdropProps {
  isLoading: boolean;
  texts: string[]; // Array of texts to animate
}

export const LoadingCarBackdrop: React.FC<LoadingBackdropProps> = ({ isLoading, texts }) => {
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    if (!isLoading || texts.length === 0) return;

    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000); // Change text every 2 seconds

    return () => clearInterval(interval);
  }, [isLoading, texts.length]);

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          animate={{ opacity: 1 }}
          className='tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-90'
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}>
          <div className='tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center'>
            {/* Left Building Icon */}
            <div className='tw-absolute tw-bottom-1/2 tw-left-1/2 -tw-translate-x-[175px] tw-translate-y-[30px]'>
              <BuildingOfficeIcon className='tw-h-16 tw-w-16 tw-text-white' />
            </div>

            {/* Animated Text that changes dynamically */}
            <div className={'tw-absolute tw-bottom-1/2 tw-left-0 tw-right-0 -tw-translate-y-[40px] '}>
              <motion.div
                animate={{
                  opacity: 1,
                  y: 0,
                }}
                className=' tw-text-center tw-text-xl tw-font-semibold tw-text-white'
                exit={{
                  opacity: 0,
                  y: -10,
                }}
                initial={{
                  opacity: 0,
                  y: 10,
                }}
                key={textIndex}
                transition={{ duration: 0.5 }}>
                {texts[textIndex]}
              </motion.div>
            </div>

            {/* Right Building Icon */}
            <div className='tw-absolute tw-bottom-1/2 tw-left-1/2  tw-translate-x-[115px] tw-translate-y-[30px]'>
              <BuildingOfficeIcon className='tw-h-16 tw-w-16 tw-text-white' />
            </div>

            {/* Animated Car Icon */}
            <motion.div
              animate={{
                x: [-100, 100],
                y: [0],
              }}
              transition={{
                duration: 2,
                ease: 'easeInOut',
                repeat: Infinity,
              }}>
              <TruckIcon className='tw-h-16 tw-w-16 tw-text-white' />
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
