import React from 'react';
import { motion } from 'framer-motion';
import { IAddInsuranceFormValues } from './types';
import { VehicleInsuranceFormStepE, VehicleTypeE } from './vehicleInsuranceEnum';
import { Pencil } from 'lucide-react';

interface SummarySectionProps {
  values: IAddInsuranceFormValues;
  onEdit: (step: VehicleInsuranceFormStepE) => void;
}

// Funkcja pomocnicza do formatowania wartości
const formatValue = (value: any): string => {
  if (value === undefined || value === null || value === '') return '-';
  if (value instanceof Date) return value.toLocaleDateString('pl-PL');
  if (typeof value === 'boolean') return value ? 'Tak' : 'Nie';
  if (Array.isArray(value)) return value.join(', ');
  return value.toString();
};

// Komponent wyświetlający pojedynczy wiersz (etykieta – wartość)
const SummaryRow: React.FC<{ label: string; value: any; onEdit?: () => void }> = ({ label, value, onEdit }) => (
  <div className='tw-flex tw-justify-between tw-gap-2 tw-border-b tw-border-gray-200 tw-py-2'>
    <span className='tw-flex-1 tw-font-medium'>{label}</span>
    <span>{formatValue(value)}</span>

    {onEdit && (
      <Pencil
        className={'tw-ml-1 tw-inline-block tw-h-4 tw-w-4 tw-cursor-pointer tw-text-gray-500'}
        onClick={() => onEdit()}
      />
    )}
  </div>
);

const SummarySection: React.FC<SummarySectionProps> = ({ values, onEdit }) => {
  // Grupujemy dane w sekcje, przypisując polskie etykiety
  const sections: { title: string; items: { label: string; value: any; step: VehicleInsuranceFormStepE }[] }[] = [
    {
      title: 'Dane pojazdu',
      items: [
        {
          label: 'Data pierwszej rejestracji',
          value: values.vehicleFirstRegistrationDate,
          step: VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES,
        }, // Add step
        { label: 'VIN pojazdu', value: values.vehicleVin, step: VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES }, // Add step
        {
          label: 'Numer rejestracyjny',
          value: values.vehiclePlatesNumber,
          step: VehicleInsuranceFormStepE.BASIC_INFO_3_VALUES,
        }, // Add step
      ],
    },
    {
      title: 'Dane właściciela',
      items: [
        { label: 'PESEL', value: values.pesel, step: VehicleInsuranceFormStepE.OWNER_PESEL }, // Add step
        { label: 'Imię', value: values.firstName, step: VehicleInsuranceFormStepE.OWNER_FIRST_NAME }, // Add step
        { label: 'Nazwisko', value: values.lastName, step: VehicleInsuranceFormStepE.OWNER_LAST_NAME }, // Add step
        { label: 'Telefon', value: values.phone, step: VehicleInsuranceFormStepE.OWNER_PHONE_NUMBER }, // Add step
        { label: 'Email', value: values.email, step: VehicleInsuranceFormStepE.OWNER_EMAIL }, // Add step
        // { label: 'Rejestracja pojazdu na', value: values.ownerSectionVehicleRegisterTo },
        // { label: 'Ubezpieczony właściciel', value: values.ownerSectionIsPolicyholder },
        // { label: 'Właściciel pojazdu', value: values.ownerSectionIsOwnerOfVehicle },
        // { label: 'Główny użytkownik pojazdu', value: values.ownerSectionIsPrimaryVehicleUser },
        {
          label: 'Kod pocztowy właściciela',
          value: values.ownerSectionPostCode,
          step: VehicleInsuranceFormStepE.OWNER_POST_CODE,
        }, // Add step
        {
          label: 'Miejscowość właściciela',
          value: values.ownerSectionCity,
          step: VehicleInsuranceFormStepE.OWNER_POST_CODE,
        }, // Add step
        {
          label: 'Telefon właściciela',
          value: values.ownerSectionPhoneNumber,
          step: VehicleInsuranceFormStepE.OWNER_PHONE_NUMBER,
        }, // Add step - duplicated label, might need review
        { label: 'Email właściciela', value: values.ownerSectionEmail, step: VehicleInsuranceFormStepE.OWNER_EMAIL }, // Add step - duplicated label, might need review
        // { label: 'Inny użytkownik pojazdu', value: values.additionalUserSectionIsAnotherVehicleUser },
      ],
    },
    {
      title: 'Polisa',
      items: [
        {
          label: 'Data rozpoczęcia polisy',
          value: values.policyStartDate,
          step: VehicleInsuranceFormStepE.POLICY_START_DATE,
        }, // Add step
        // { label: 'Rodzaj polisy', value: values.policyType },
      ],
    },
    {
      title: 'Szczegóły pojazdu',
      items: [
        { label: 'Typ pojazdu', value: values.vehicleType, step: VehicleInsuranceFormStepE.VEHICLE_TYPE }, // Add step
        { label: 'Marka', value: values.vehicleBrand, step: VehicleInsuranceFormStepE.VEHICLE_BRAND }, // Add step
        {
          label: 'Rok produkcji',
          value: values.vehicleProductionYear,
          step: VehicleInsuranceFormStepE.VEHICLE_PRODUCTION_YEAR,
        }, // Add step
        {
          label: 'Rok zakupu',
          value: values.vehiclePurchaseYear,
          step: VehicleInsuranceFormStepE.VEHICLE_PURCHASE_YEAR,
        }, // Add step
        { label: 'Model', value: values.vehicleModel, step: VehicleInsuranceFormStepE.VEHICLE_MODEL }, // Add step
        // { label: 'Model MOQ', value: values.vehicleModelGov },
        { label: 'Rodzaj paliwa', value: values.vehicleFuelType, step: VehicleInsuranceFormStepE.VEHICLE_FUEL_TYPE }, // Add step
        {
          label: 'Pojemność silnika',
          value: values.vehicleEngineCapacity + ' cm3',
          step: VehicleInsuranceFormStepE.VEHICLE_ENGINE_CAPACITY,
        }, // Add step
        {
          label: 'Moc silnika',
          value: values.vehicleEnginePower,
          step: VehicleInsuranceFormStepE.VEHICLE_ENGINE_POWER,
        }, // Add step
        {
          label: 'Typ skrzyni biegów',
          value: values.vehicleGearboxType,
          step: VehicleInsuranceFormStepE.VEHICLE_GEARBOX_TYPE,
        }, // Add step
        { label: 'Typ', value: values.vehicleEquipmentType, step: VehicleInsuranceFormStepE.VEHICLE_SECOND_TYPE }, // Add step - label 'Typ' might need review
        // { label: 'Model AE', value: values.vehicleModelAutoExpert },
        // { label: 'Wersja AE', value: values.vehicleVersionAutoExpert },
        {
          label: 'Liczba miejsc',
          value: values.vehicleNumberOfSeats,
          step: VehicleInsuranceFormStepE.VEHICLE_NUMBER_OF_SEATS,
        }, // Add step
        {
          label: 'Aktualny przebieg',
          value: values.vehicleCurrentMileage + ' km',
          step: VehicleInsuranceFormStepE.VEHICLE_CURRENT_MILEAGE,
        },
        ...(values.policyType.some((type) => type.toLowerCase() === 'ac')
          ? [
              {
                label: 'Orientacyjna wartość pojazdu',
                value: values.vehicleApproximateValue + ' zł',
                step: VehicleInsuranceFormStepE.VEHICLE_APPROXIMATE_VALUE,
              }, // Add step
            ]
          : []),
      ],
    },
    {
      title: 'Inne dane',
      items: [
        {
          label: 'Szacowany roczny przebieg',
          value: values.otherDataSectionVehicleEstimatedAnnualMileage,
          step: VehicleInsuranceFormStepE.ESTIMATED_ANNUAL_MILEAGE,
        }, // Add step
        {
          label: 'Miejsce parkowania',
          value: values.otherDataSectionParkingPlaceAtNight,
          step: VehicleInsuranceFormStepE.PARKING_PLACE,
        }, // Add step
        {
          label: 'Szacowane użycie za granicą',
          value: values.otherDataSectionVehicleEstimatedUsedAbroad,
          step: VehicleInsuranceFormStepE.ESTIMATED_USED_ABROAD,
        }, // Add step
        {
          label: 'Pojazd importowany',
          value: values.otherDataSectionVehicleWasImported,
          step: VehicleInsuranceFormStepE.WAS_VEHICLE_IMPORTED,
        }, // Add step
        ...(values.vehicleType !== VehicleTypeE.MOTORCYCLE
          ? [
              {
                label: 'Kierownica po prawej',
                value: values.otherDataSectionVehicleSteeringWheelOnRightSide,
                step: VehicleInsuranceFormStepE.IS_STEERING_WHEEL_ON_RIGHT,
              }, // Add step
              {
                label: 'Zarejestrowany jako ciężarówka',
                value: values.otherDataSectionVehicleRegisteredAsTruck,
                step: VehicleInsuranceFormStepE.IS_REGISTERED_AS_TRUCK,
              }, // Add step
            ]
          : []),
      ],
    },
  ];

  if (values.policyType.some((type) => type.toLowerCase() === 'oc')) {
    sections.push({
      title: 'Historia OC',
      items: [
        ...(values.ocSectionLastInsuredByCurrentOwner
          ? [
              {
                label: 'Ostatnio ubezpieczony przez obecnego właściciela',
                value: values.ocSectionLastInsuredByCurrentOwner,
                step: VehicleInsuranceFormStepE.LAST_INSURED_BY_CURRENT_OWNER, // Add step
              },
              {
                label: 'Poprzednia firma ubezpieczeniowa',
                value: values.ocSectionPrevInsuredCompany,
                step: VehicleInsuranceFormStepE.PREV_INSURED_COMPANY,
              }, // Add step
            ]
          : [
              {
                label: 'Czy poprzedni właściciel miał OC',
                value: values.ocSectionPrevOwnerHadOc,
                step: VehicleInsuranceFormStepE.PREV_OWNER_HAD_OC,
              }, // Add step
              {
                label: 'Firma OC poprzedniego właściciela',
                value: values.ocSectionPrevOwnerOcInsuredCompany,
                step: VehicleInsuranceFormStepE.PREV_OWNER_OC_INSURED_COMPANY,
              }, // Add step
            ]),
      ],
    });
  }
  if (values.policyType.some((type) => type.toLowerCase() === 'ac')) {
    sections.push({
      title: 'Historia AC',
      items: [
        ...(values.acSectionLastInsuredByCurrentOwner
          ? [
              {
                label: 'Ostatnio ubezpieczony przez obecnego właściciela',
                value: values.acSectionLastInsuredByCurrentOwner,
                step: VehicleInsuranceFormStepE.AC_LAST_INSURED_BY_CURRENT_OWNER, // Add step
              },
              {
                label: 'Poprzednia firma ubezpieczeniowa',
                value: values.acSectionPrevInsuredCompany,
                step: VehicleInsuranceFormStepE.AC_PREV_INSURED_COMPANY,
              },
            ]
          : [
              {
                label: 'Czy poprzedni właściciel miał AC',
                value: values.acSectionPrevOwnerHadAc,
                step: VehicleInsuranceFormStepE.AC_PREV_OWNER_HAD_AC,
              },
              {
                label: 'Firma AC poprzedniego właściciela',
                value: values.acSectionPrevOwnerAcInsuredCompany,
                step: VehicleInsuranceFormStepE.AC_PREV_OWNER_AC_INSURED_COMPANY,
              },
            ]),
      ],
    });
  }

  // Dodajemy sekcję leasingową tylko, gdy "isPolicyholderLeasedMoq" wynosi "Tak"
  if (values.leasedSectionIsPolicyholder) {
    sections.push({
      title: 'Sekcja leasingowa',
      items: [
        { label: 'NIP leasingobiorcy', value: values.leasedSectionNip, step: VehicleInsuranceFormStepE.LEASING_DATA },
        {
          label: 'REGON leasingobiorcy',
          value: values.leasedSectionRegon,
          step: VehicleInsuranceFormStepE.LEASING_DATA,
        },
        {
          label: 'Pełna nazwa leasingobiorcy',
          value: values.leasedSectionFullName,
          step: VehicleInsuranceFormStepE.LEASING_DATA,
        },
        {
          label: 'Kod PKD leasingobiorcy',
          value: values.leasedSectionPkd,
          step: VehicleInsuranceFormStepE.LEASING_DATA,
        },
        {
          label: 'Kod pocztowy leasingobiorcy',
          value: values.leasedSectionPostCode,
          step: VehicleInsuranceFormStepE.LEASING_DATA,
        },
        {
          label: 'Telefon leasingobiorcy',
          value: values.leasedSectionPhoneNumber,
          step: VehicleInsuranceFormStepE.LEASING_DATA,
        },
        {
          label: 'Email leasingobiorcy',
          value: values.leasedSectionEmail,
          step: VehicleInsuranceFormStepE.LEASING_DATA,
        },
      ],
    });
  }

  // Sekcja właściciela (MOQ) – zawsze wyświetlamy
  // sections.push({
  //   title: 'Sekcja właściciela',
  //   items: [
  //     { label: 'Pojazd zarejestrowany na', value: values.vehicleRegisteredToMoq },
  //     { label: 'Właściciel jako ubezpieczony', value: values.isOwnerPolicyholderMoq },
  //     { label: 'Właściciel pojazdu', value: values.isOwnerOfVehicleMoq },
  //     { label: 'Główny użytkownik', value: values.isPrimaryVehicleUserMoq },
  //     { label: 'Kod pocztowy właściciela', value: values.ownerPostCodeMoq },
  //     { label: 'Telefon właściciela', value: values.ownerPhoneNumberMoq },
  //     { label: 'Email właściciela', value: values.ownerEmailMoq },
  //   ],
  // });

  // Dodajemy sekcję dodatkowego użytkownika, jeżeli dotyczy (wartość "Tak")
  // if (values.AdditionalUserSectionIsAnotherVehicleUser === 'Tak') {
  //   sections.push({
  //     title: 'Dodatkowy użytkownik pojazdu',
  //     items: [
  //       { label: 'Inny użytkownik pojazdu', value: values.AdditionalUserSectionIsAnotherVehicleUser },
  //       { label: 'Data urodzenia', value: values.AdditionalUserSectionBornDate },
  //       { label: 'Data uzyskania prawa jazdy', value: values.AdditionalUserSectionObtainingDrivingLicenseDate },
  //     ],
  //   });
  // }

  // Definiujemy warianty animacji dla Framer Motion
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div animate='visible' className='tw-space-y-6' initial='hidden' variants={containerVariants}>
      {sections.map((section, idx) => (
        <motion.div
          className='tw-transform tw-rounded-lg tw-bg-white tw-py-2  tw-transition-transform hover:tw-scale-105'
          key={idx}
          variants={itemVariants}>
          <h2 className='tw-mb-4 tw-text-2xl tw-font-semibold tw-text-nau-kids-gray'>{section.title}</h2>
          <div>
            {section.items.map((item, index) => (
              <SummaryRow key={index} label={item.label} onEdit={() => onEdit(item.step)} value={item.value} />
            ))}
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default SummarySection;
