import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { VehicleInsuranceQuotation, VehicleInsuranceResultData } from './types';
import { Landmark, PiggyBank, Wallet } from 'lucide-react';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { twMerge } from '../../../index';
import agent from '../../../api/agent';

// ----- Helper functions for insurance company logo -----
const normalizeCompanyName = (name: string): string => {
  return name.toLowerCase().replace(/\s/g, '');
};

const getInsuranceCompanyLogo = (companyName: string): string => {
  const logos: Record<string, string> = {
    agrotuw: '/assets/insuranceCompanies/agrotuw.png',
    allianz: '/assets/insuranceCompanies/allianz.svg',
    balcia: '/assets/insuranceCompanies/balcia.png',
    beesafe: '/assets/insuranceCompanies/beesafe.png',
    benefia: '/assets/insuranceCompanies/benefia.png',
    compensa: '/assets/insuranceCompanies/compensa.png',
    euroins: '/assets/insuranceCompanies/euroins.png',
    generali: '/assets/insuranceCompanies/generali.svg',
    hestia: '/assets/insuranceCompanies/ergo.svg',
    interrisk: '/assets/insuranceCompanies/interrisk.svg',
    link4: '/assets/insuranceCompanies/link4.png',
    mtu: '/assets/insuranceCompanies/mtu.svg',
    mtu24: '/assets/insuranceCompanies/mtu24.png',
    proama: '/assets/insuranceCompanies/proama.svg',
    pzu: '/assets/insuranceCompanies/pzu.png',
    trasti: '/assets/insuranceCompanies/trasti.png',
    tuw: '/assets/insuranceCompanies/tuw.png',
    tuz: '/assets/insuranceCompanies/tuz.svg',
    uniqa: '/assets/insuranceCompanies/uniqa.svg',
    warta: '/assets/insuranceCompanies/warta.png',
    hdi: '/assets/insuranceCompanies/hdi.png',
    wiener: '/assets/insuranceCompanies/wiener.svg',
    gothaer: '/assets/insuranceCompanies/gothaer.png',
    youcandrive: '/assets/insuranceCompanies/youcandrive.png',
  };

  const normalizedName = normalizeCompanyName(companyName);

  // Find the first matching key using `includes`
  const matchedKey = Object.keys(logos).find((key) => normalizedName.includes(key));

  return matchedKey ? logos[matchedKey] : '';
};

// ----- Constants for piggy bank balance and discount -----
const piggyBankBalance = 500;

interface IVehicleInsuranceOffersProps {
  data: VehicleInsuranceResultData;
}

export function VehicleInsuranceOffers(props: IVehicleInsuranceOffersProps) {
  // For handling which offer was clicked and opening the payment dialog
  const [selectedOffer, setSelectedOffer] = useState<VehicleInsuranceQuotation | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isPaymentLinkLoading, setIsPaymentLinkLoading] = useState<boolean>(false);

  const handleOfferSelect = (offer: any) => {
    setSelectedOffer(offer);
    setDialogOpen(true);
  };

  const getNumberFromString = (str: string): number => {
    return parseFloat(str.replace(/\s/g, '').replace(',', '.'));
  };

  const getDiscountedPrice = (price: string): number => {
    const discountAmount = Math.min(piggyBankBalance, getNumberFromString(price) * 0.25);
    return Math.max(getNumberFromString(price) - discountAmount, 0);
  };

  const handlePaymentOption = async (option: 'reduced' | 'full') => {
    let price = getNumberFromString(selectedOffer?.price || '');
    if (option === 'reduced') {
      price = getDiscountedPrice(selectedOffer?.price ?? '');
    }
    try {
      setIsPaymentLinkLoading(true);
      const link = await agent.AiOffice.payVehicleInsurance({
        price: price,
        name: selectedOffer?.name || '',
      });
      window.location.href = link;
    } finally {
      setIsPaymentLinkLoading(false);
    }
    setDialogOpen(false);
    setSelectedOffer(undefined);
  };

  return (
    <>
      <div className='tw-mb-6  tw-rounded-md tw-bg-gradient-to-r tw-from-nau-green-light tw-to-nau-green-light tw-p-2 tw-text-center tw-text-lg  tw-text-white'>
        <div className={'tw-flex tw-items-center tw-justify-center'}>
          <PiggyBank className={'tw-inline tw-h-12 tw-w-12'} />
        </div>
        <span className='tw-font-semibold'>Saldo skarbonki NAU:</span>

        <div className={'tw-flex tw-items-center tw-justify-center tw-font-bold'}>{piggyBankBalance} zł</div>
      </div>
      <div className='tw-mb-4 tw-text-center tw-text-2xl tw-font-bold tw-text-nau-kids-gray'>Przygotowane oferty</div>

      <div className='tw-grid tw-grid-cols-1 tw-gap-6'>
        {props.data?.companysQuotation?.length === 0 && (
          <div className='tw-text-center tw-text-lg tw-font-semibold tw-text-nau-kids-gray'>
            Brak ofert dla podanego pojazdu
          </div>
        )}
        {props.data.companysQuotation
          .sort(
            (a, b) =>
              parseFloat(a.price.replace(/\s/g, '').replace(',', '.')) -
              parseFloat(b.price.replace(/\s/g, '').replace(',', '.')),
          )
          .map((item) => {
            // Calculate discounted prices (ensure the value is not negative)
            const discountedPrice = getDiscountedPrice(item.price);
            const hasInstallment = item.installment && item.installment !== '-';

            return (
              <div
                className='from-indigo-500 to-purple-500 border border-gray-300 tw-transform tw-rounded-xl tw-bg-gradient-to-r tw-p-6 tw-shadow-lg tw-transition-transform'
                key={item.id}>
                <div className='tw-mb-4 tw-flex tw-flex-col tw-items-center tw-justify-between sm:tw-flex-row'>
                  <h3 className='tw-text-xl tw-font-semibold tw-text-nau-kids-gray sm:tw-text-2xl'>{item.name}</h3>
                  {getInsuranceCompanyLogo(item.name) && (
                    <img
                      alt={`${item.name} logo`}
                      className='tw-mr-3 tw-h-[90px] tw-w-[90px] tw-rounded-md tw-bg-white tw-object-contain'
                      src={getInsuranceCompanyLogo(item.name)}
                    />
                  )}
                </div>

                <div className='tw-mb-4'>
                  <div className='tw-flex tw-items-center tw-text-nau-kids-gray'>
                    <Wallet className='tw-mr-1 tw-h-5 tw-w-5' />
                    <span className='tw-font-bold'>Cena:</span>
                    <span className={twMerge('tw-ml-1', piggyBankBalance > 0 && 'tw-line-through')}>
                      {item.price} zł
                    </span>
                  </div>
                  {piggyBankBalance > 0 && (
                    <div className='tw-mt-1 tw-flex tw-items-center tw-text-lg tw-text-green-500'>
                      <span className='tw-font-bold'>Ze skarbonką NAU:</span>
                      <span className='tw-ml-1 tw-font-bold'>{discountedPrice} zł</span>
                    </div>
                  )}
                </div>
                {hasInstallment && (
                  <div className='tw-mb-4'>
                    <div className='tw-flex tw-items-center tw-text-nau-kids-gray'>
                      <Landmark className='tw-mr-1 tw-h-5 tw-w-5' />
                      <span className='tw-font-bold'>Lub 12 rat miesięcznych po:</span>
                      <span className='tw-ml-1 '>{item.installment} zł</span>
                    </div>
                  </div>
                )}

                <div className='tw-flex tw-justify-end tw-text-nau-kids-gray'>
                  <MyButton onClick={() => handleOfferSelect(item)} type={'button'} variant='primary'>
                    Wybieram
                  </MyButton>
                </div>
              </div>
            );
          })}
      </div>

      {/* Radix Dialog for Payment Selection */}
      <Dialog.Root onOpenChange={setDialogOpen} open={dialogOpen}>
        <Dialog.Overlay className='tw-fixed tw-inset-0 tw-z-50 tw-bg-black/50' />
        <Dialog.Content className='tw-fixed tw-left-1/2 tw-top-1/2 tw-z-50 tw-w-full tw-max-w-md -tw-translate-x-1/2 -tw-translate-y-1/2 tw-transform tw-rounded-lg tw-bg-white tw-p-6 tw-shadow-xl'>
          <Dialog.Title className='tw-mb-4 tw-text-xl tw-font-bold'>Wybierz sposób płatności</Dialog.Title>
          {selectedOffer && (
            <div className='tw-mb-4'>
              <div className='tw-mb-2'>
                <span className='tw-font-semibold'>Pełna cena:</span>{' '}
                <span className={twMerge(piggyBankBalance > 0 && 'tw-line-through')}> {selectedOffer.price} zł</span>
              </div>
              <div className='tw-mb-2 tw-font-semibold tw-text-green-600'>
                <span className='tw-font-semibold'>Cena ze skarbonką NAU:</span>{' '}
                {Math.max(
                  getNumberFromString(selectedOffer.price) -
                    Math.min(piggyBankBalance, getNumberFromString(selectedOffer.price) * 0.25),
                  0,
                )}{' '}
                zł
              </div>
              {selectedOffer.installment && selectedOffer.installment !== '-' && (
                <>
                  <div className='tw-mb-2 tw-mt-4'>
                    <span className=''>Lub 12 rat miesięcznych po:</span>
                    <span className='tw-ml-1'>{selectedOffer.installment} zł</span>
                  </div>
                </>
              )}
            </div>
          )}
          <div className='tw-flex tw-flex-col tw-justify-end tw-gap-2 tw-pt-4 sm:tw-flex-row'>
            <MyButton
              isLoading={isPaymentLinkLoading}
              onClick={() => handlePaymentOption('full')}
              type={'button'}
              variant='gray'>
              Zapłać pełną cenę
            </MyButton>
            <MyButton
              isLoading={isPaymentLinkLoading}
              onClick={() => handlePaymentOption('reduced')}
              type={'button'}
              variant='primary'>
              Zapłać obniżoną cenę
            </MyButton>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
