import { VehicleInsuranceForm } from './VehicleInsuranceForm';
import React from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import { useSwrAgent } from '../../../api/useSwrAgent';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';

export default function VehicleInsuranceFormCustomerDashboardWrapper() {
  const { data: userProfile, isLoading: isLoadingUserProfile } = useSwrAgent().Users.GetUserProfile();
  return (
    <>
      <CustomerDashboard>
        {isLoadingUserProfile ? <LoadingComponent /> : <VehicleInsuranceForm userProfile={userProfile} />}
      </CustomerDashboard>
    </>
  );
}
