export enum VehicleInsuranceFormStepE {
  // Basic information (GOV lookup step)
  BASIC_INFO_3_VALUES = 'basicInfo3Values',

  POLICY_TYPE = 'policyType',

  // Insurance policy information
  POLICY_START_DATE = 'policyStartDate',

  // Vehicle details (populated from GOV data or user input)
  VEHICLE_TYPE = 'vehicleType',
  VEHICLE_BRAND = 'vehicleBrand',
  VEHICLE_PRODUCTION_YEAR = 'vehicleProductionYear',
  VEHICLE_PURCHASE_YEAR = 'vehiclePurchaseYear',
  VEHICLE_MODEL = 'vehicleModel',
  VEHICLE_FUEL_TYPE = 'vehicleFuelType',
  VEHICLE_ENGINE_CAPACITY = 'vehicleEngineCapacity',
  VEHICLE_ENGINE_POWER = 'vehicleEnginePower',
  VEHICLE_GEARBOX_TYPE = 'vehicleGearboxType',
  VEHICLE_SECOND_TYPE = 'vehicleSecondType',
  VEHICLE_MODEL_AUTO_EXPERT = 'vehicleModelAutoExpert',
  VEHICLE_VERSION_AUTO_EXPERT = 'vehicleVersionAutoExpert',
  VEHICLE_NUMBER_OF_SEATS = 'vehicleNumberOfSeats',
  VEHICLE_CURRENT_MILEAGE = 'vehicleCurrentMileageMoq',
  VEHICLE_APPROXIMATE_VALUE = 'vehicleApproximateValue',

  // Additional vehicle details
  ESTIMATED_ANNUAL_MILEAGE = 'estimatedAnnualMileage',
  PARKING_PLACE = 'parkingPlace',
  ESTIMATED_USED_ABROAD = 'estimatedUsedAbroad',
  WAS_VEHICLE_IMPORTED = 'wasVehicleImported',
  IS_STEERING_WHEEL_ON_RIGHT = 'isSteeringWheelOnRight',
  IS_REGISTERED_AS_TRUCK = 'isRegisteredAsTruck',
  IS_VEHICLE_LEASED = 'isVehicleLeased',
  LEASING_DATA = 'isVehicleLeased',

  // OC history information
  LAST_INSURED_BY_CURRENT_OWNER = 'lastInsuredByCurrentOwnerMoq',
  PREV_INSURED_COMPANY = 'prevInsuredCompanyMoq',
  PREV_OWNER_HAD_OC = 'prevOwnerHadOcMoq',
  PREV_OWNER_OC_INSURED_COMPANY = 'prevOwnerOcInsuredCompanyMoq',

  // AC history information
  AC_LAST_INSURED_BY_CURRENT_OWNER = 'acLastInsuredByCurrentOwner',
  AC_PREV_INSURED_COMPANY = 'acPrevInsuredCompany',
  AC_PREV_OWNER_HAD_AC = 'acPrevOwnerHadAc',
  AC_PREV_OWNER_AC_INSURED_COMPANY = 'acPrevOwnerAcInsuredCompany',

  OWNER_PESEL = 'ownerPesel',
  OWNER_FIRST_NAME = 'ownerFirstName',
  OWNER_LAST_NAME = 'ownerLastName',
  OWNER_EMAIL = 'ownerEmail',
  OWNER_PHONE_NUMBER = 'ownerPhoneNumber',
  OWNER_POST_CODE = 'ownerPostCode',

  SUMMARY = 'summary',
  OFFER_DOWNLOAD = 'offerDownload',
}

export enum VehicleTypeE {
  CAR = 'Samochody osobowe i Samochody terenowe',
  MOTORCYCLE = 'Motocykle',
  TRUCK = 'Ciężarowe pow. 3,5t',
}
