import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MaskHelpers from '../../helpers/MaskHelpers';
import agent from '../../api/agent';
import { getNestedValue } from '../../utils/objectUtils';
import { MyTextInputRHF } from '../FormControls/ReactHookForm/MyTextInputRHF';

interface IAddressPromptingFormProps {
  postCodeFormikName: string;
  cityFormikName: string;
  streetFormikName?: string;
  houseNoFormikName: string;
  flatNoFormikName: string;
  placeholderInsteadOfLabel?: boolean;
}

export const AddressPromptingFormRHF = (props: IAddressPromptingFormProps) => {
  const {
    postCodeFormikName,
    cityFormikName,
    streetFormikName,
    houseNoFormikName,
    flatNoFormikName,
    placeholderInsteadOfLabel,
  } = props;

  const { control, setValue, watch, register } = useFormContext();
  const values = watch(); // Watch all form values

  const [citySuggestions, setCitySuggestions] = React.useState<string[]>([]);
  const [streetSuggestions, setStreetSuggestions] = React.useState<string[]>([]);

  const zipCodePattern = /^\d{2}-\d{3}$/;
  const zipCodeInFormIsCorrect = zipCodePattern.test(getNestedValue(values, postCodeFormikName));

  const fetchCitySuggestions = async (cityName: string, withAutoFill?: boolean) => {
    const zipCode = getNestedValue(values, postCodeFormikName);
    const response = await agent.Address.getCities({
      postcode: zipCode ?? '',
      city: cityName,
      limit: 15,
    });
    const filteredCities = response.filter(Boolean);
    setCitySuggestions(filteredCities);

    if (withAutoFill && filteredCities.length === 1) {
      const foundCity = filteredCities[0];
      setValue(cityFormikName, foundCity);
      const streets = await fetchStreetSuggestions('', foundCity, zipCode);
      const filteredStreets = streets.filter(Boolean);
      if (filteredStreets.length === 1 && streetFormikName) {
        setValue(streetFormikName, filteredStreets[0]);
      }
    } else {
      await fetchStreetSuggestions('', cityName, zipCode);
    }
  };

  const handlePostCodeChange = async (postCode: string) => {
    const response = await agent.Address.getCities({
      postcode: postCode ?? '',
      limit: 15,
    });
    const filteredCities = response.filter(Boolean);
    setCitySuggestions(filteredCities);

    if (filteredCities.length === 1) {
      const foundCity = filteredCities[0];
      setValue(cityFormikName, foundCity);

      const streets = await fetchStreetSuggestions('', foundCity, postCode);
      const filteredStreets = streets.filter(Boolean);

      if (filteredStreets.length === 1 && streetFormikName) {
        setValue(streetFormikName, filteredStreets[0]);
      }
    }
  };

  const fetchStreetSuggestions = async (street: string, city?: string, zipCode?: string) => {
    const zipCodeForApi = zipCode ?? getNestedValue(values, postCodeFormikName);
    const cityForApi = city ?? getNestedValue(values, cityFormikName);
    const response = await agent.Address.getStreets({
      postcode: zipCodeForApi ?? '',
      city: cityForApi ?? '',
      street: street,
      limit: 15,
    });

    setStreetSuggestions(response.filter(Boolean));
    return response;
  };

  const clearAddressInfo = (clearCity: boolean, clearStreet: boolean) => {
    if (clearCity) {
      setCitySuggestions([]);
      setValue(cityFormikName, '');
    }
    if (clearStreet && streetFormikName) {
      setStreetSuggestions([]);
      setValue(streetFormikName, '');
    }
  };

  return (
    <>
      <div className='tw-flex tw-w-full tw-flex-col tw-gap-4 sm:tw-flex-row'>
        {/* Post Code Input */}
        <Controller
          control={control}
          name={postCodeFormikName}
          render={({ field }) => (
            <MyTextInputRHF
              {...field}
              // Pass the obligatory register prop
              label={placeholderInsteadOfLabel ? undefined : 'Kod pocztowy'}
              mask={MaskHelpers.postCode}
              onChange={(event) => {
                field.onChange(event);
                clearAddressInfo(true, true);
                if (zipCodePattern.test(event.target.value)) {
                  handlePostCodeChange(event.target.value);
                }
              }}
              placeholder={placeholderInsteadOfLabel ? 'Kod pocztowy' : undefined}
              register={register}
              wrapperClassName='sm:tw-w-[110px] tw-w-full'
            />
          )}
        />

        {/* City Input */}
        <Controller
          control={control}
          name={cityFormikName}
          render={({ field }) => (
            <MyTextInputRHF
              {...field}
              disabled={!zipCodeInFormIsCorrect}
              label={placeholderInsteadOfLabel ? undefined : 'Miejscowość'}
              onChange={(event) => {
                field.onChange(event);
                clearAddressInfo(false, true);
                fetchCitySuggestions(event.target.value);
              }}
              onSuggestionClick={(suggestion: string) => {
                clearAddressInfo(false, true);
                fetchCitySuggestions(suggestion, true);
              }}
              placeholder={placeholderInsteadOfLabel ? 'Miejscowość' : undefined}
              register={register}
              suggestions={citySuggestions}
              wrapperClassName='tw-grow'
            />
          )}
        />
      </div>

      {/* Street Input */}
      {/* {streetFormikName && ( */}
      {/*   <Controller */}
      {/*     control={control} */}
      {/*     name={streetFormikName} */}
      {/*     render={({ field }) => ( */}
      {/*       <MyTextInputRHF */}
      {/*         {...field} */}
      {/*         disabled={!zipCodeInFormIsCorrect} */}
      {/*         label={placeholderInsteadOfLabel ? undefined : 'Ulica'} */}
      {/*         onChange={(event) => { */}
      {/*           field.onChange(event); */}
      {/*           fetchStreetSuggestions(event.target.value); */}
      {/*         }} */}
      {/*         onSuggestionClick={(suggestion: string) => { */}
      {/*           fetchStreetSuggestions(suggestion); */}
      {/*         }} */}
      {/*         placeholder={placeholderInsteadOfLabel ? 'Ulica' : undefined} */}
      {/*         register={register} */}
      {/*         suggestions={streetSuggestions} */}
      {/*         wrapperClassName='tw-w-full' */}
      {/*       /> */}
      {/*     )} */}
      {/*   /> */}
      {/* )} */}

      {/* /!* House Number Input *!/ */}
      {/* <Controller */}
      {/*   control={control} */}
      {/*   name={houseNoFormikName} */}
      {/*   render={({ field }) => ( */}
      {/*     <MyTextInputRHF */}
      {/*       {...field} */}
      {/*       disabled={!zipCodeInFormIsCorrect} */}
      {/*       label={placeholderInsteadOfLabel ? undefined : 'Numer domu'} */}
      {/*       placeholder={placeholderInsteadOfLabel ? 'Numer domu' : undefined} */}
      {/*       register={register} */}
      {/*       wrapperClassName='tw-w-full' */}
      {/*     /> */}
      {/*   )} */}
      {/* /> */}

      {/* /!* Flat Number Input *!/ */}
      {/* <Controller */}
      {/*   control={control} */}
      {/*   name={flatNoFormikName} */}
      {/*   render={({ field }) => ( */}
      {/*     <MyTextInputRHF */}
      {/*       {...field} */}
      {/*       disabled={!zipCodeInFormIsCorrect} */}
      {/*       label={placeholderInsteadOfLabel ? undefined : 'Numer mieszkania'} */}
      {/*       placeholder={placeholderInsteadOfLabel ? 'Numer mieszkania' : undefined} */}
      {/*       register={register} */}
      {/*       wrapperClassName='tw-w-full' */}
      {/*     /> */}
      {/*   )} */}
      {/* /> */}
    </>
  );
};
