import React, { InputHTMLAttributes, useState } from 'react';
import InputMask from 'react-input-mask';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

export interface TextInputBaseProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  mask?: string;
  error?: string;
  suggestions?: string[];
  onSuggestionClick?: (suggestion: string) => void;
  wrapperClassName?: string;
  labelClassName?: string;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const TextInputBase = ({
  label,
  mask,
  error,
  suggestions = [],
  onSuggestionClick,
  wrapperClassName,
  labelClassName,
  onFocus,
  onBlur,
  inputRef,
  ...inputProps
}: TextInputBaseProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const hasError = Boolean(error);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus && onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur && onBlur(e);
  };

  const handleSuggestionClick = (e: React.MouseEvent, suggestion: string) => {
    e.preventDefault();
    onSuggestionClick && onSuggestionClick(suggestion);
    setIsFocused(false);
  };

  return (
    <div className={twMerge(inputProps.hidden && 'tw-hidden', wrapperClassName)}>
      {label && (
        <label
          className={clsx(
            'tw-block tw-text-left tw-text-sm tw-font-medium tw-leading-6',
            labelClassName ?? 'tw-text-gray-900',
          )}
          htmlFor={inputProps.id || inputProps.name?.toString()}>
          {label}
        </label>
      )}
      <div className='tw-relative tw-mt-1 tw-rounded-md tw-shadow-sm'>
        {mask ? (
          <InputMask
            mask={mask}
            {...inputProps}
            className={twMerge(
              'tw-block tw-w-full tw-rounded-xl tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-3 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-400 sm:tw-text-sm sm:tw-leading-6',
              hasError && 'tw-placeholder:text-red-300 tw-pr-10 tw-text-red-900 tw-ring-red-300 focus:tw-ring-red-500',
              inputProps.disabled && 'tw-bg-gray-100 tw-opacity-80',
              inputProps.className,
            )}
            inputRef={inputRef} // note: react-input-mask accepts inputRef
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        ) : (
          <input
            {...inputProps}
            className={twMerge(
              'tw-block tw-w-full tw-rounded-xl tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-3 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-400 sm:tw-text-sm sm:tw-leading-6',
              hasError && 'tw-placeholder:text-red-300 tw-pr-10 tw-text-red-900 tw-ring-red-300 focus:tw-ring-red-500',
              inputProps.disabled && 'tw-bg-gray-100 tw-opacity-80',
              inputProps.className,
            )}
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={inputRef} // use inputRef as a normal prop here
          />
        )}
        {hasError && (
          <div className='tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3'>
            <ExclamationCircleIcon aria-hidden='true' className='tw-h-5 tw-w-5 tw-text-red-500' />
          </div>
        )}
      </div>
      <div className='tw-relative tw-w-full'>
        {isFocused && suggestions.length > 0 && (
          <ul className='tw-absolute tw-left-0 tw-right-0 tw-top-[5px] tw-z-50 tw-max-h-40 tw-overflow-auto tw-rounded-b-md tw-bg-white tw-shadow-md'>
            {suggestions.map((suggestion, index) => (
              <li
                className='tw-cursor-pointer tw-px-3 tw-py-2 hover:tw-bg-gray-100'
                key={index}
                onMouseDown={(e) => handleSuggestionClick(e, suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div
        className={twMerge(
          'tw-transition-grid-rows tw-grid tw-overflow-hidden tw-duration-200',
          hasError ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]',
        )}>
        <p className='tw-min-h-0 tw-text-left tw-text-sm tw-text-red-600'>{error || '\u00A0'}</p>
      </div>
    </div>
  );
};
