import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import LogoHeader from '../../../components/LogoHeader/LogoHeader';
import { Button } from 'react-bootstrap';
import useQuery from '../../../hooks/useQuery';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';
import CinemaVoucherOrderSuccess from '../../../components/CinemaVoucher/CinemaVoucherOrderSuccess/CinemaVoucherOrderSuccess';
import KlThankYouPage from '../../Kl/ThankYouPage/KlThankYouPage';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import { motion } from 'framer-motion';

export default observer(() => {
  const { paymentsStore } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCinemaVoucher, setIsCinemaVoucher] = useState<boolean>(false);
  const [isKl, setIsKl] = useState<boolean>(false);
  const [showText, setShowText] = useState(false);

  const query: any = useQuery();
  const orderId = query.get('OrderID');
  useEffect(() => {
    if (orderId) {
      paymentsStore
        .checkOrderCategory(orderId)
        .then((resp) => {
          if (resp === 1) setIsCinemaVoucher(true);
          else if (resp === 2) setIsKl(true);
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setIsLoading(false);
          setTimeout(() => setShowText(true), 500);
        });
    }
  }, []);

  return (
    <>
      <LoadingComponent visible={isLoading} />
      {isCinemaVoucher ? (
        <CustomerDashboard>
          <CinemaVoucherOrderSuccess fromPayment={true} paymentOrderId={orderId} />
        </CustomerDashboard>
      ) : isKl ? (
        <CustomerDashboard>
          <KlThankYouPage />
        </CustomerDashboard>
      ) : (
        <div className='tw-flex tw-min-h-screen tw-items-center tw-justify-center tw-bg-gradient-to-b tw-from-teal-600 tw-to-teal-900 tw-p-6 tw-text-white'>
          <motion.div
            animate={{
              opacity: 1,
              y: 0,
            }}
            className='tw-max-w-lg tw-rounded-2xl tw-bg-white tw-p-8 tw-text-center tw-text-gray-900 tw-shadow-lg'
            initial={{
              opacity: 0,
              y: -30,
            }}
            transition={{ duration: 1 }}>
            <motion.div
              animate={{ scale: 1 }}
              className='tw-mb-6 tw-flex tw-justify-center'
              initial={{ scale: 0 }}
              transition={{
                type: 'spring',
                stiffness: 200,
                damping: 10,
              }}>
              <LogoHeader />
            </motion.div>

            <motion.h3
              animate={{
                opacity: 1,
                y: 0,
              }}
              className='tw-text-2xl tw-font-bold tw-text-teal-700'
              initial={{
                opacity: 0,
                y: 10,
              }}
              transition={{
                duration: 1,
                delay: 0.5,
              }}>
              Dziękujemy za płatność!
            </motion.h3>

            {showText && (
              <motion.p
                animate={{
                  opacity: 1,
                  y: 0,
                }}
                className='tw-mt-4 tw-text-gray-700'
                initial={{
                  opacity: 0,
                  y: 10,
                }}
                transition={{
                  duration: 1,
                  delay: 1.5,
                }}>
                Polisa opłacona. W ciągu od 30 minut do 5 godzin odezwie się nasz pracownik lub otrzymasz dokumenty na
                maila. Polisa będzie dostępna w tej aplikacji.
              </motion.p>
            )}

            <motion.div
              animate={{
                opacity: 1,
                y: 0,
              }}
              className='tw-mt-6'
              initial={{
                opacity: 0,
                y: 10,
              }}
              transition={{
                duration: 1,
                delay: 2,
              }}>
              <Button
                className='tw-transform tw-rounded-lg tw-bg-teal-600 tw-px-6 tw-py-2 tw-font-semibold tw-text-white tw-transition-all hover:tw-scale-105 hover:tw-bg-teal-700'
                onClick={() => window.open('/app', '_self', 'location=no')}>
                Powrót do aplikacji
              </Button>
            </motion.div>
          </motion.div>
        </div>
      )}
    </>
  );
});
