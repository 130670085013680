// that is for strings like '27KM (20kW)'
export function extractKmAndKw(input?: string): { km?: number; kw?: number } {
  if (!input) {
    return { km: undefined, kw: undefined };
  }
  const kmMatch = input.match(/(\d+)\s*KM/);
  const kwMatch = input.match(/(\d+)\s*kW/i);
  return {
    km: kmMatch ? parseInt(kmMatch[1], 10) : undefined,
    kw: kwMatch ? parseInt(kwMatch[1], 10) : undefined,
  };
}
