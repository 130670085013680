import * as React from 'react';
import { ReactNode } from 'react';
import * as styles from './styles';
import { Image } from 'react-bootstrap';
import { twMerge } from 'tailwind-merge';

interface ISubpageHeaderProps {
  title: string | (() => ReactNode);
  iconSrc?: string;
  iconHeight?: number;
  headerClassName?: string;
  iconComponent?: React.ReactNode;
}

const SubpageHeader = (props: ISubpageHeaderProps) => {
  const renderTitle = () => {
    if (typeof props.title === 'function') {
      return props.title();
    }
    return props.title;
  };
  return (
    <div style={styles.tabsBox}>
      <div>
        <h1
          className={twMerge(
            'tw-flex tw-items-center tw-justify-center tw-px-2 tw-text-center tw-text-white',
            props.headerClassName,
          )}>
          {props.iconSrc && (
            <Image
              src={props.iconSrc}
              style={
                { marginLeft: '5px', marginRight: '15px', height: props.iconHeight ?? '25px' } as React.CSSProperties
              }
            />
          )}
          {props.iconComponent && <div className={'tw-ml-[5px] tw-mr-[15px]'}>{props.iconComponent}</div>}
          <>{renderTitle()}</>
        </h1>
      </div>
      <div
        style={
          {
            paddingTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
          } as React.CSSProperties
        }></div>
    </div>
  );
};

export default SubpageHeader;
