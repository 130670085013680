import React from 'react';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { TextInputBase, TextInputBaseProps } from '../MyTextInput/TextInputBase';

export interface MyTextInputRHFProps<T extends FieldValues> extends Omit<TextInputBaseProps, 'inputRef'> {
  name: Path<T>;
  register: UseFormRegister<T>;
}

export function MyTextInputRHF<T extends FieldValues>({
  name,
  register,
  onChange,
  onBlur,
  ...restProps
}: MyTextInputRHFProps<T>) {
  const field = register(name);
  const { ref, onChange: rhfOnChange, onBlur: rhfOnBlur, ...fieldProps } = field;

  // If you need to run additional logic, you can wrap the onChange and onBlur.
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    rhfOnChange(e);
    if (onChange) onChange(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    rhfOnBlur(e);
    if (onBlur) onBlur(e);
  };

  return <TextInputBase {...restProps} {...fieldProps} inputRef={ref} onBlur={handleBlur} onChange={handleChange} />;
}
