import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { toast } from 'react-toastify';
import LoadingComponentPartial from '../../Loadings/LoadingComponentPartial/LoadingComponentPartial';
import { CinemaVoucherOrderDataM } from '../../../types/cinemaVoucher';
import { history } from '../../../index';
import { PathRoute } from '../../../constants/pathRoute/Route';
import { CinemaVoucherOrderPromo } from '../../../enums/cinemaVoucherOrderPromo.enum';
import agent from '../../../api/agent';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { UserPromotionE } from '../../../enums/userPromotion.enums';
import PlusButtonIcon from '../../Icons/PlusButtonIcon';
import MinusButtonIcon from '../../Icons/MinusButtonIcon';
import CustomBlueToast from '../../Toast/CustomBlueToast';

export default observer((props: { orderId: number }) => {
  const { cinemaVoucherStore, modalStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [order, setOrder] = useState<CinemaVoucherOrderDataM>({} as CinemaVoucherOrderDataM);
  const [showMaxQuantityToast, setShowMaxQuantityToast] = useState<boolean>(false);

  const { data: userMarketingData, isLoading: areMarketingDataLoading } = useSwrAgent().Users.GetUserMarketingData();

  const { data: isUserAssignedToInstitutionAsDirector, isLoading: isLoadingIsUserAssignedToInstitutionAsDirector } =
    useSwrAgent().Users.IsUserAssignedToInstitutionAsDirector();

  const { data: hasUserCollectedFreeCinemaVouchers, isLoading: isLoadingHasUserCollectedFreeCinemaVouchers } =
    useSwrAgent().CinemaVouchers.CheckUserHasCinemaVoucherOrderWithPromo(
      CinemaVoucherOrderPromo.InstitutionDirectorPromo,
    );

  const isCinemaDirectorPromo =
    userMarketingData &&
    userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolDirectors);
  const showDirectorPromo =
    userMarketingData &&
    userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolDirectors);

  const isDirectorPromoLayout =
    isUserAssignedToInstitutionAsDirector && !hasUserCollectedFreeCinemaVouchers && showDirectorPromo;

  const handleConfirm = async () => {
    setIsSubmitting(true);
    cinemaVoucherStore
      .checkUserHasCinemaVoucherPersonalData()
      .then((resp) => {
        if (resp) {
          sendOrder();
        } else {
          history.push(PathRoute.CINEMA_VOUCHER_ORDER_CLIENT_DATA + '/' + props.orderId);
        }
      })
      .finally(() => {
        modalStore.closeModal();
        setIsSubmitting(false);
      });
  };
  const updateOrder = () => {
    cinemaVoucherStore
      .sendOrder(true, cinemaVoucherStore.promoId, order.id)
      .then((resp) => {
        getOrder(resp);
      })
      .catch(() => {
        toast.error('Brak kodów do zamówienia.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const increment = (idx: number, companyId: number) => {
    if (isDirectorPromoLayout && cinemaVoucherStore.cinemaVoucherCartQuantity >= 2) {
      setShowMaxQuantityToast(true);
      return;
    }
    if (cinemaVoucherStore.cinemaVoucherCartQuantity >= 50) {
      setShowMaxQuantityToast(true);
      return;
    }
    const newCompanies = [...cinemaVoucherStore.companies];
    const company = newCompanies.filter((x) => x.id == companyId)[0];
    let amount = company.sum;
    amount += company.types[idx].amount;
    if (company.types[idx].quantity >= company.types[idx].availableQuantity) {
      toast.error('Nie można wybrać więcej kodów tego typu');
    } else {
      company.types[idx].quantity += 1;
      company.sum = amount;
    }
    let companiesSum = 0;
    newCompanies.forEach((element) => {
      companiesSum += element.sum;
    });
    cinemaVoucherStore.setCompanies(newCompanies).then(() => {
      updateOrder();
    });
  };

  const decrement = (idx: number, companyId: number) => {
    const newCompanies = [...cinemaVoucherStore.companies];
    const company = newCompanies.filter((x) => x.id == companyId)[0];
    let amount = company.sum;
    amount -= company.types[idx].amount;
    if (company.types[idx].quantity >= 1) {
      company.types[idx].quantity -= 1;
      company.sum = amount;
    }
    cinemaVoucherStore.setCompanies(newCompanies).then(() => {
      updateOrder();
    });
  };

  const sendOrder = () => {
    if (order.orderPromoId === CinemaVoucherOrderPromo.InstitutionDirectorPromo) {
      agent.CinemaVouchers.orderDirectorPromoCinemaVouchers(props.orderId)
        .then(() => {
          history.push(PathRoute.CINEMA_VOUCHER_PROMO_DIRECTOR_ORDER_SUMMARY);
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    } else if (order.orderPromoId === CinemaVoucherOrderPromo.NauEmployeePromo) {
      agent.CinemaVouchers.orderNauEmployeePromoCinemaVouchers(props.orderId)
        .then(() => {
          history.push(PathRoute.CINEMA_VOUCHER_PROMO_NAU_ORDER_SUMMARY);
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    } else if (order.orderPromoId === CinemaVoucherOrderPromo.PersonalPromotion) {
      agent.CinemaVouchers.orderPersonalPromoCinemaVouchers(props.orderId)
        .then(() => {
          history.push(PathRoute.CINEMA_VOUCHER_PROMO_NAU_ORDER_SUMMARY);
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    } else if (order.orderPromoId === CinemaVoucherOrderPromo.GrandparentsDay) {
      agent.CinemaVouchers.orderGrandparentsPromoCinemaVouchers(props.orderId)
        .then((resp) => {
          window.open(resp, '_self', 'location=no');
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    } else if (order.orderPromoId === CinemaVoucherOrderPromo.WinterBreak) {
      agent.CinemaVouchers.orderWinterBreakPromoCinemaVouchers(props.orderId)
        .then((resp) => {
          window.open(resp, '_self', 'location=no');
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    } else {
      cinemaVoucherStore
        .orderCinemaVouchers(props.orderId)
        .then((resp) => {
          window.open(resp, '_self', 'location=no');
          cinemaVoucherStore.setCompanies([]);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error);
        });
    }
  };
  const getOrder = (orderId: number) => {
    cinemaVoucherStore
      .getCinemaVoucherOrderData(orderId.toString())
      .then((resp) => {
        setOrder(resp);
        setLoading(false);
        if (resp.vouchers.length === 0) {
          modalStore.closeModal();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };
  useEffect(() => {
    getOrder(props.orderId);
  }, []);
  return (
    <div className={'tw-flex tw-justify-center tw-bg-white tw-leading-normal'}>
      {loading ? (
        <LoadingComponentPartial />
      ) : (
        <div className={'tw-w-full tw-flex-1 tw-p-2'}>
          {showMaxQuantityToast && (
            <CustomBlueToast
              onClose={() => setShowMaxQuantityToast(false)}
              text={
                isDirectorPromoLayout
                  ? 'Możesz odebrać 2 darmowe kupony'
                  : 'Limit wynosi maks. 50 sztuk \nprzy jednorazowym zamówieniu.'
              }
            />
          )}
          <p className='tw-mb-8 tw-text-center tw-text-2xl'>Podsumowanie</p>
          {!order.orderPromoId && !isDirectorPromoLayout && (
            <>
              {cinemaVoucherStore.companies.length > 0 &&
                cinemaVoucherStore.companies.map((company) => (
                  <div key={company.id}>
                    {company.types.length > 0 &&
                      company.types
                        .filter((x) => x.quantity > 0)
                        .map((code, idx) => (
                          <div className='tw-mb-4 tw-flex tw-w-full tw-justify-between tw-gap-4' key={idx}>
                            <div>{code.companyName}</div>
                            <div className='tw-flex tw-w-[160px] tw-items-center tw-justify-between'>
                              <div
                                className={
                                  'tw-mr-4 tw-flex tw-justify-center tw-gap-2 tw-text-xl tw-font-bold tw-text-teal-700'
                                }>
                                <div className='tw-relative tw-flex tw-gap-2 tw-text-center '>
                                  {code.amount * code.quantity} zł
                                </div>
                              </div>
                              <div className='tw-flex tw-justify-center'>
                                <button
                                  className='tw-border-none tw-bg-transparent tw-text-teal-700'
                                  onClick={() => decrement(idx, company.id)}>
                                  <MinusButtonIcon className={'tw-h-6 tw-w-6'} />
                                </button>
                                <div className='tw-mx-1 tw-flex tw-h-[25px] tw-w-[40px] tw-items-center tw-justify-center tw-rounded-lg tw-border-[3px] tw-border-[#B0B6BA]'>
                                  {code.quantity}
                                </div>
                                <button
                                  className='tw-border-none tw-bg-transparent tw-text-teal-700'
                                  onClick={() => increment(idx, company.id)}>
                                  <PlusButtonIcon className={'tw-h-6 tw-w-6'} />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                ))}
            </>
          )}
          <div className={'tw-flex tw-flex-col tw-gap-4'}>
            {(order.orderPromoId || isDirectorPromoLayout) && (
              <>
                {order.vouchers.map((x, idx) => (
                  <div className='tw-flex tw-w-full tw-justify-between tw-gap-4' key={idx}>
                    <div>{x.companyName}</div>
                    <div className={'tw-whitespace-nowrap'}>{x.details}</div>
                  </div>
                ))}
              </>
            )}
            <div className='tw-flex tw-w-full tw-justify-between tw-gap-4'>
              <div>
                {order.orderPromoId != CinemaVoucherOrderPromo.InstitutionDirectorPromo &&
                  order.orderPromoId != CinemaVoucherOrderPromo.NauEmployeePromo &&
                  order.orderPromoId != CinemaVoucherOrderPromo.PersonalPromotion && (
                    <p className='tw-pb-2 tw-text-left'>+ Opłata serwisowa 1 zł</p>
                  )}
              </div>
            </div>
          </div>
          <div className={'tw-my-6 tw-h-[1px] tw-w-full tw-border-t tw-border-black'}></div>
          <div className='tw-flex tw-justify-between tw-pb-2'>
            <div>Suma</div>
            <div className='tw-text-xl tw-font-bold tw-text-teal-700'>{order.sum}</div>
          </div>
          <div className='tw-flex tw-flex-col tw-gap-2 tw-p-4'>
            {isSubmitting ? (
              <Button className='tw-w-full' disabled={true}>
                <span className='tw-m-1'>
                  <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
                </span>
                Ładowanie…
              </Button>
            ) : (
              <Button className='tw-w-full' onClick={handleConfirm}>
                Zamów
              </Button>
            )}
            <Button
              onClick={() => {
                history.push(
                  `${isCinemaDirectorPromo ? PathRoute.CINEMA_VOUCHER_DIRECTOR_PROMO : PathRoute.CINEMA_VOUCHER}?orderView=true`,
                );
                modalStore.closeModal();
              }}
              variant='secondary'>
              Wróć
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});
