import { motion } from 'framer-motion';
import { MySelectInputOptions } from '../MySelectInput/types';
import { twMerge } from '../../../index';

interface OptionBoxSelectProps {
  label: string;
  name: string;
  value: any;
  options: MySelectInputOptions[];
  onChange: (value: any) => void;
  error?: string;
  wrapperClassName?: string;
  itemClassName?: string;
  renderOption?: (option: MySelectInputOptions) => React.ReactNode;
}

export const MySelectBoxInput = ({
  label,
  name,
  value,
  options,
  onChange,
  error,
  wrapperClassName,
  itemClassName,
  renderOption,
}: OptionBoxSelectProps) => {
  return (
    <div>
      <label className='tw-mb-4 tw-block tw-font-bold tw-text-gray-600'>{label}</label>
      <div className={twMerge('tw-flex tw-flex-wrap tw-gap-2', wrapperClassName)}>
        {options.map((option) => (
          <motion.div
            className={twMerge(
              `tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-border tw-p-2 tw-text-center ${
                value === option.value
                  ? 'tw-bg-nau-sea-green tw-text-white'
                  : 'tw-border-gray-300 tw-bg-gray-600 tw-text-white'
              }`,
              itemClassName,
            )}
            key={option.value.toString()}
            onClick={() => onChange(option.value)}
            whileHover={{ scale: 1.05 }}>
            {renderOption ? renderOption(option) : option.label}
          </motion.div>
        ))}
      </div>
      {error && <p className='tw-mt-1 tw-text-sm tw-text-red-500'>{error}</p>}
    </div>
  );
};
